$colors-hotspot-image: (
	bg-marker: rgba(#FFF, 1),
	border-marker: color(blue, $colors-primary),
	color-marker: color(blue, $colors-primary),

	bg-content: rgba(#FFF, 1),
	border-content: color(blue, $colors-primary),
	color-content: color(blue, $colors-primary),
);

.hotspot-image {
	position: relative;
}

.hotspot-image__background-image {
	width: 100%;
}

.hotspot-image__hotspot {
	position: absolute;
}

.hotspot-image__hotspot-marker {
	$marker: &;

	display: flex;
	justify-content: center;
	align-items: center;

	&-control {
		width: 32px;
		height: 32px;

		background-color: color(bg-marker, $colors-hotspot-image);
		border: 2px solid color(border-marker, $colors-hotspot-image);
		border-radius: 50%;
		outline: none;
		cursor: pointer;

		&:focus {
			background-color: color(border-marker, $colors-hotspot-image);

			#{$marker}-icon {
				color: color(bg-marker, $colors-hotspot-image);
			}
		}
	}

	&-icon {
		color: color(color-marker, $colors-hotspot-image);
	}
}

.hotspot-image__hotspot-content {
	background-color: color(bg-content, $colors-hotspot-image);
	border-bottom: 2px solid color(border-content, $colors-hotspot-image);
	border-left: 2px solid color(border-content, $colors-hotspot-image);
	color: color(color-content, $colors-hotspot-image);

	padding: 1rem;

	position: absolute;
	width: 240px;

	.rich-text {
		max-width: 80ch;
	}

	&-control {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		outline: none;
		cursor: pointer;
		border: none;
		background-color: transparent;

		&:focus {
			border: 2px solid color(border-marker, $colors-hotspot-image);
		}
	}

	&-controls {
		display: flex;
		justify-content: flex-start;

		margin-bottom: 1rem;
	}

	&-icon {
		color: color(color-content, $colors-hotspot-image);
		cursor: pointer;
	}
}

// Open state
.hotspot-image__hotspot {
	$hotspot: &;

	&-content {
		display: none;
	}

	&.is-open {
		#{$hotspot}-content {
			display: block;
		}
	}
}

// Content alignment
.hotspot-image__hotspot {
	$hotspot: &;

	&.-align-top {
		#{$hotspot}-content {
			top: 0;
		}
	}

	&.-align-bottom {
		#{$hotspot}-content {
			bottom: 0;
		}
	}

	&.-align-right {
		#{$hotspot}-content {
			right: 0;
		}
	}

	&.-align-left {
		#{$hotspot}-content {
			left: 0;
		}
	}
}
