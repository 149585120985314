/* ----------------------------------------------------------------------------
 * Compass fallback
 * --------------------------------------------------------------------------*/

@function generated-image-url($url) {
  @return url('../images/' + $url);
}

@function sprite($map, $name) {
  $filename: 'sprites/' + $map + "/" + $name + ".png";
  @return generated-image-url($filename) no-repeat center center;
}

@mixin sprite-dimensions($sprite-map, $icon-name) {
  $filename: 'sprites/' + $sprite-map + "/" + $icon-name + ".png";
  width: image-width($filename);
  height: image-height($filename);
}


/* ----------------------------------------------------------------------------
 * Vaillant utils
 * --------------------------------------------------------------------------*/

@mixin image-container() {
  &.left {
    float: left;
    margin-right: gutter();
  }

  &.right {
    float: right;
    margin-left: gutter();
  }

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.image-container {
  @include image-container();
}

@mixin rowify($numElements) {
  @include span(map-get($susy, columns) / $numElements);

  $gutter-position: map-get($susy, gutter-position);

  @if $gutter-position == before {
    &:nth-child(#{$numElements}n+1) {
      @include first;
    }
  } @else if $gutter-position == after {
    &:nth-child(#{$numElements}n+#{$numElements}) {
      @include last;
    }
  }

  &:nth-child(#{$numElements}n+1) {
    clear: both;
  }
}


@mixin eye-catcher($size, $label-font-size, $background-color: rgba(0, 137, 126, 1)) {
  position: absolute;
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $background-color;
  text-align: center;

  $gradientOverlay: linear-gradient(to top, rgba(21,159,125,0.8) 0%,rgba(21,159,125,0.2) 100%);

  background-image: $gradientOverlay, generated-image-url('layout/stripes_white.png');
  background-repeat: repeat;
  background-position: center center;

  .label {
    position: relative;
    font-family: "CompatilFact", "Segeo UI", Roboto, Calibri, Helvetica, sans-serif;
    font-size: $label-font-size;
    color: #FFF;
    text-align: center;

    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
}

@mixin icon-small($icon-name, $position: "left") {
  @include icon($icons-xsmall, $icon-name, $position);
}

@mixin icon-medium($icon-name, $position: "left") {
  @include icon($icons-small, $icon-name, $position);
}

@mixin arrow($direction, $size, $color) {
  &:after, &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba($color, 0);
    border-top-color: $color;
    border-width: $size;
    margin-left: $size;
  }
  // &:before {
  //     border-color: rgba($border-color, 0);
  //     border-top-color: $border-color;
  //     border-width: 14px;
  //     margin-left: -14px;
  // }
}

@mixin arrow-box($border-color) {
  position: relative;
  background: #FFF;
  border: 1px solid $border-color;

  &:after, &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #FFF;
    border-width: 13px;
    margin-left: -13px;
  }

  &:before {
    border-color: rgba($border-color, 0);
    border-top-color: $border-color;
    border-width: 14px;
    margin-left: -14px;
  }
}


@mixin icon($sprite-map, $icon-name, $position, $size: 0) {
  display: inline-block;
  white-space: nowrap;

  @if $position == "right" {
    &:after {
      @include pseudo-element-defaults();
      background: sprite($sprite-map, $icon-name);
      background-repeat: no-repeat;
      margin-left: 1rem;

      @if $size == 0 {
        @include sprite-dimensions($sprite-map, $icon-name);
      } @else {
        width: $size;
        height: $size;
      }
      vertical-align: top;
    }
  } @else {
    &:before {
      @include pseudo-element-defaults();
      background: sprite($sprite-map, $icon-name);
      background-repeat: no-repeat;
      margin-right: 1rem;

      @if $size == 0 {
        @include sprite-dimensions($sprite-map, $icon-name);
      } @else {
        width: $size;
        height: $size;
      }

      vertical-align: top;
    }
  }
}
