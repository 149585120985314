.compact-navigation-bar {
	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__logo {
		margin-top: 2rem;

		.logo__link {
			width: 13rem;
		}
	}

	@include breakpoint(bp(compact-navigation-bar)) {
		&__content {
			flex-direction: row;
		}

		&__logo {
			margin-top: 0;
			margin-left: auto;
		}
	}
}

// Variant: logo first
.compact-navigation-bar {
	$root: &;

	&--logo-first {
		#{$root} {
			&__content {
				flex-direction: column-reverse;
			}

			&__logo {
				margin-top: 0;
				margin-bottom: 2rem;
			}

			@include breakpoint(bp(compact-navigation-bar)) {
				&__content {
					flex-direction: row;
				}

				&__logo {
					margin-bottom: 0;
				}
			}
		}
	}
}
