.article-teaser {
  $root: &;

  &--slider {
    #{$root} {

    }

    .article-teaser__image-heading {
      bottom: 1rem;
      left: 2.4rem;
      right: 1.4rem;

      span {
        left: 0;
      }
    }
  }
}