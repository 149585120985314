$colors-dialog: (
	bg-overlay: rgba(0, 0, 0, 0.66),
	bg: #FFF,
	title: color(vaillant, $colors-brand),
	bg-button: transparent,
	border-button: color(darker-grey, $colors-neutral),
	border-button-hover: color(vaillant, $colors-brand),
	button-icon: color(darker-grey, $colors-neutral),
	button-icon-hover: color(vaillant, $colors-brand),
);
$dialog-padding: 2rem;
$title-bar-margin: 3rem;

[data-a11y-dialog-native] > .dialog-container__overlay {
	display: none;
}

.dialog-container[aria-hidden='true'] {
	display: none;
}

dialog[open] {
	display: block;
}

.dialog-container {
	.dialog {
		animation: appear transition(slower) 150ms both;
	}
}

.dialog-container {
	background-color: color(bg-overlay, $colors-dialog);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: layer(dialog-container);
}

.dialog-container__overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: layer(dialog-overlay);
}

.dialog {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: layer(dialog);

	padding: $dialog-padding;

	background-color: color(bg, $colors-dialog);
}

.dialog__title-bar {
	display: flex;
	margin-bottom: $title-bar-margin;
}

.dialog__close-button {
	@include default-button();

	display: flex;
	justify-content: center;
	align-items: center;

	margin-left: auto;
	padding: 0;
	width: 4rem;
	height: 4rem;

	.icon-container__icon {
		display: block;
		color: color(button-icon, $colors-dialog);
	}

	&, &:focus {
		background-color: color(bg-button, $colors-dialog);
		border: 1px solid color(border-button, $colors-dialog);
		border-radius: 50%;
	}

	&:hover, &:active {
		background-color: color(bg-button, $colors-dialog);
		border-color: color(border-button-hover, $colors-dialog);

		.icon-container__icon {
			color: color(button-icon-hover, $colors-dialog);
		}
	}
}

.dialog__content {
	max-width: calc(90vw - #{$dialog-padding});
	max-height: calc(90vh - #{$dialog-padding} - #{$title-bar-margin});
}

.dialog__image {
	object-fit: contain;

	width: 100%;
	height: 100%;
}
