.interview {
  margin: 3rem 0;
}

.interview {
  &__entry {
    font-size: 1.4rem;
    line-height: 1.5em;

    &--question {
      margin-bottom: 0 !important;
      font-weight: normal;
      &:after { content: "" };
    }

    &--answer {
      margin-bottom: 0 !important;
      margin: 0;
      padding: 0;
    }

    &-name {
      text-transform: uppercase;
      font-weight: bold;
      user-select: none;
    }
  }

  @include breakpoint($bp-mobile-only) {
    &__entry {
      &--question, &--answer {
        margin-bottom: 0;

        // TODO: open
        padding-top: 1.5em;
        padding-bottom: 2em;
      }
      &--question, &--answer, &-name {
        padding-left: $horizontal-site-padding;
        padding-right: $horizontal-site-padding;
      }

      &-name {
        display: block;
        margin-bottom: -1px;
        border: 1px solid map_get($colors-neutral, dark-grey);
        border-left-width: 0;
        border-right-width: 0;

        padding-top: 1em;
        padding-bottom: 1em;

        cursor: pointer;

        // TODO: open
        margin-bottom: 0;
        margin-bottom: 1.5em;
        background-color: map_get($colors-neutral, light-grey);

        &:after {
          content: " ";
          $iconWidth: 50px;
          $iconHeight: 50px;

          width: $iconWidth;
          height: $iconHeight;

          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -$iconHeight / 2;

          background-repeat: no-repeat;
        };
      }
    }
  }

  @include breakpoint($bp-medium) {
    &__entry {
      &--question, &--answer {
        margin-bottom: 0.5em !important;
      }

      &--question {
        margin-top: 3em;

        &:first-child {
          margin-top: 0;
        }
      }

      &-name {
        display: inline;
        text-transform: none;
        &:after {
          content: ":  "
        };
      }
    }
  }
}