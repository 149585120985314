.module {
	@include content-wrapper();
	scroll-margin-top: 8rem;
}

.module {
  &.-overwrite-child-margin {
    > * {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.module {
  &.margin-top {
    &-none {
      //margin-top: 0;
    }

    &-small {
      margin-top: 0.5rem;
    }

    &-medium {
      margin-top: 1rem;
    }

    &-large {
      margin-top: 2.5rem;
    }
  }

  &.margin-bottom {
    &-none {
      //margin-bottom: 0;
    }

    &-small {
      margin-bottom: 0.5rem;
    }

    &-medium {
      margin-bottom: 1rem;
    }

    &-large {
      margin-bottom: 3.75rem;
    }
  }
}

.module {
  @include breakpoint(bp(sm)) {
    &.margin-top {
      &-none {
        //margin-top: 0;
      }

      &-small {
        margin-top: 1rem;
      }

      &-medium {
        margin-top: 2rem;
      }

      &-large {
        margin-top: 5rem;
      }
    }

    &.margin-bottom {
      &-none {
        //margin-bottom: 0;
      }

      &-small {
        margin-bottom: 1.5rem;
      }

      &-medium {
        margin-bottom: 3rem;
      }

      &-large {
        margin-bottom: 7.5rem;
      }
    }
  }
}

.module {
  @include breakpoint(bp(lg)) {
    &.margin-top {
      &-none {
        //margin-top: 0;
      }

      &-small {
        margin-top: 2rem;
      }

      &-medium {
        margin-top: 4rem;
      }

      &-large {
        margin-top: 10rem;
      }
    }

    &.margin-bottom {
      &-none {
        //margin-bottom: 0;
      }

      &-small {
        margin-bottom: 2rem;
      }

      &-medium {
        margin-bottom: 4rem;
      }

      &-large {
        margin-bottom: 10rem;
      }
    }
  }
}

.module {
  @include breakpoint(bp(xxl)) {
    &.margin-top {
      &-none {
        //margin-top: 0;
      }

      &-small {
        margin-top: 3rem;
      }

      &-medium {
        margin-top: 6rem;
      }

      &-large {
        margin-top: 15rem;
      }
    }

    &.margin-bottom {
      &-none {
        //margin-bottom: 0;
      }

      &-small {
        margin-bottom: 3rem;
      }

      &-medium {
        margin-bottom: 6rem;
      }

      &-large {
        margin-bottom: 15rem;
      }
    }
  }
}
