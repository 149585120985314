@mixin unstyled-list() {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin unstyled-link() {
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;

  &:active, &:focus {
    outline: none;
  }
}

@function font-size($name) {
    @return map-get($font-sizes, $name);
}

@function line-height($name) {
    @return map-get($line-heights, $name);
}

@function headings() {
    @return [h1, h2, h3, h4, h5, h6];
}

@function headings-small() {
    @return [h5, h6];
}

@mixin background-heading($background-color, $color, $offset-x: 0) {
  color: $color;
  display: inline;
  line-height: 2.1;

  left: $offset-x + 0.65em;

  padding: 0.4em 0 0.4em 0;
  background-color: $background-color;
  box-shadow: 0.6em 0 0 $background-color, -0.65em 0 0 $background-color;
  box-decoration-break: clone;
  text-transform: uppercase;
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$unit1: unit($min-vw);
	$unit2: unit($max-vw);
	$unit3: unit($min-font-size);
	$unit4: unit($max-font-size);
	@if $unit1 == $unit2 and $unit1 == $unit3 and $unit1 == $unit4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}
