.article-teaser {
  $backgroundOverlay: linear-gradient(to top, rgba(255,255,255,0.3) 0%,rgba(255,255,255,0.65) 100%);
  $root: &;
  $innerPad: 2rem;

  &--full {
    border: none;

    #{$root} {
      &__image {
        position: relative;

        img {
          width: 100%;
          height: 100%;
          @include object-fit(cover);
        }

        &-background, &-link {
          position: relative;
          width: 100%;
          height: 100%;
        }

        &-heading {
          @include breakpoint($bp-medium) {
           span {
             font-size: 2.2rem;
           }
          }
          @include breakpoint($bp-large) {
           span {
             font-size: 2.8rem;
           }
          }
        }
      }

      &__content {
        background-image: $backgroundOverlay, generated-image-url('layout/stripes_light-blue.png');
        background-position: center top;
        background-repeat: no-repeat, repeat;

        padding: $innerPad;
        padding-bottom: $innerPad * 1.5;

        &-subheading {

        }

        &-teaser-text {
          margin-bottom: 2rem;

        }
      }

      &__controls {
        margin-bottom: 0;
        margin-top: auto;
        &-read-story {

        }
      }
    }

    @include breakpoint($bp-medium) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      min-height: 350px;

      #{$root} {
        &__image {
          width: span(6 of 12);
        }

        &__content {
          width: span(6 of 12);
        }
      }
    }


    @include breakpoint($bp-large) {
      #{$root} {
        &__image {
          width: span(8 of 12);
        }

        &__content {
          width: span(4 of 12);
        }
      }
    }
  }
}

.article-teaser-collection__entry {
  &:nth-child(2n+2) {
    .article-teaser--full {
      .article-teaser {
        &__image {
          order: 2;
        }

        &__content {
          order: 1;
        }
      }
    }
  }
}