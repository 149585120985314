$categoryColor: map_get($colors-primary, blue) !default;

@mixin apply-category-color($categoryColor) {
  > header {
    .category-title {
      color: $categoryColor;
    }

    .titled-image {
      .title {
        background-color: $categoryColor;

        h1 {
          span {
            background-color: $categoryColor;
          }
        }

        @include breakpoint($bp-medium) {
          background-color: transparent;
        }
      }
    }
  }

  .main-content {
    h3, h5 {
      color: $categoryColor;
    }
  }
}

.article__hero {
  position: relative;
  margin-bottom: 3rem;

  &-image {
    width: 100%;
    overflow: hidden;

    @include breakpoint($bp-medium) {
      max-height: 450px;
    }

    @include breakpoint($bp-large) {
      max-height: 550px;
    }

    img {
      width: 100%;
      height: 100%;
      @include object-fit(cover, center center);
    }
  }

  .content-wrapper {
    @include breakpoint($bp-mobile-only) {
      background-color: $categoryColor;
    }
  }

  &-heading-wrapper {
    @include breakpoint($bp-medium) {
      position: absolute;
      bottom: 1em;
    }
  }

  &-heading {
    font-size: 2rem;
    text-transform: uppercase;

    @include breakpoint($bp-mobile-only) {
      padding: 0.6em;
      color: #FFF;
      font-size: 2.3rem;
      line-height: 1.3em;
    }

    @include breakpoint($bp-medium) {
      @include background-heading($categoryColor, #FFF);
      position: relative;
    }

    @include breakpoint($bp-large) {
      font-size: 3rem;
      bottom: 2rem;
    }
  }
}

.article__main-content {
  $content-font-size: 1.6rem;
  font-size: $content-font-size;

  h1 {
    font-size: 2rem;
    margin-bottom: 0.5em;
  }

  h2 {
    @extend %wf-headings;
    font-size: 4rem;
    margin-bottom: 0.5em;
  }

  h3 {
    color: $categoryColor;
    font-size: 2rem;
    margin-top: 2em;
    margin-bottom: 0.5em;
  }

  h4 {
    font-size: 2rem;
    margin-top: 2em;
    margin-bottom: 0.5em;
  }

  h5 {
    font-size: 1.8rem;
    color: $categoryColor;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  h6 {
    font-size: $content-font-size;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  a:not(.button) {
    color: $categoryColor;

    &:hover, &:focus, &:active {
      text-decoration: underline;
    }
  }

  ul:not([class]), ol:not([class]) {
    li {
      margin-bottom: 0.7em;
      line-height: 1.5em
    }
  }

  table:not([class]) {
    margin: 2rem 0;

    td, th {
      padding: 0.5em;
    }
  }
}

.article__comments {
  @include content-wrapper();

  .article-controls-form {
    margin-bottom: 4rem;
  }

  &-title {
    font-size: 2rem;
  }

  &-disabled {
    font-size: 1.5rem;

    margin-top: 2rem;
  }

  &-form {
    margin: 2rem 0;
  }
}

.article__modules {
	+ .article__modules {
		position: relative;
		margin-top: var(--module-spacing-md);

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background-color: map-get($colors-secondary, lime);
			max-width: $max-content-width;
			margin: 0 auto var(--module-spacing-sm);
		}
	}
}
