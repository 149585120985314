.input-radio {
  $indicator-size: 1.4rem;
  $transition-radio: 0.15s linear;
  $indicator-border-size: 0.2rem;

  display: block;
  position: relative;
  cursor: pointer;

  &__input {
    position: absolute;
    opacity: 0;
    height: 100%;

    + .input-radio__indicator:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      border: $indicator-border-size solid #FFF;

      @include center();

      transition: width $transition-radio, height $transition-radio;
      @include size(0);
    }

    &:checked + .input-radio__indicator:before {
      @include size($indicator-size - $indicator-border-size);
    }
  }

  &__indicator {
    position: absolute;
    @include size($indicator-size);
    top: 0.7em;
    margin-top: $indicator-size / -2;

    background-color: #FFF;
    border: 1px solid map_get($colors-neutral, dark-grey);
    border-radius: 50%;
  }

  &__text {
    font-size: 1.5rem;
    height: 100%;
    user-select: none;
    display: block;
    padding-left: $indicator-size * 1.4;
    line-height: 1.5;
  }
}

.life .input-radio__indicator:before {
  background-color: map-get($colors-brand, vaillant);
}