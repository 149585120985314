.module {
	--spacing-before: var(--module-spacing-default);
	--spacing-after: var(--module-spacing-default);

	&.-spacing-after-neg-sm {
		padding-bottom: calc(7rem + var(--module-spacing-sm));
		margin-bottom: var(--spacing-after-neg-sm)
	}

	&.-spacing-after-neg-md {
		padding-bottom: calc(7rem + var(--module-spacing-md));
		margin-bottom: var(--spacing-after-neg-md)
	}

	&.-spacing-after-neg-lg {
		padding-bottom: calc(7rem + var(--module-spacing-lg));
		margin-bottom: var(--spacing-after-neg-lg)
	}

	&.-spacing-before-default { margin-top: var(--spacing-before-default) }
	&.-spacing-before-none { margin-top: var(--spacing-before-none) }
	&.-spacing-before-sm { margin-top: var(--spacing-before-sm) }
	&.-spacing-before-md { margin-top: var(--spacing-before-md) }
	&.-spacing-before-lg { margin-top: var(--spacing-before-lg) }

	&.-spacing-after-default { margin-bottom: var(--spacing-after-default) }
	&.-spacing-after-none { margin-bottom: var(--spacing-after-none) }
	&.-spacing-after-sm { margin-bottom: var(--spacing-after-sm) }
	&.-spacing-after-md { margin-bottom: var(--spacing-after-md) }
	&.-spacing-after-lg { margin-bottom: var(--spacing-after-lg) }

	&__content {
		.module {
			// Remove unnecessary padding from modules which are part of another module's content.
			padding: 0;
		}
	}
}
