.slider {
    $paginationHeight: 50px;
    $directionalNavSize: 50px;

    width: 100%;

    &.pagination-above {
        padding-top: #{$paginationHeight * 3 / 2};
    }

    &.pagination-below {
        padding-bottom: $paginationHeight;
    }

    &.directional-nav-above {
        padding-top: #{$directionalNavSize * 3 / 2};
    }

    &.directional-nav-below {
        padding-bottom: $directionalNavSize;
    }

    .viewport {
        overflow: hidden;

        .slides-wrapper {
            white-space: nowrap;
            list-style: none;
            margin: 0;
            padding: 0;

            .slide {
                display: inline-block;
                white-space: normal;
                vertical-align: top;

                &.clone {

                }

                &.active {

                }
            }
        }
    }

    .directional-nav, .pagination {
        &.hidden {
            display: none !important;
        }
    }

    .pagination {
        width: 100%;
        height: $paginationHeight;
        position: absolute;

        &.above {
            top: 0;
        }

        &.below {
        }

        .dots {
            width: 100%;
            line-height: 50px;
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: center;

            &.locked {
                opacity: 0.3;
            }

            .dot {
                z-index: 10;
                display: inline-block;
                width: 10px;
                height: 10px;
                border: 1px solid #A0A0A0;
                border-radius: 50%;
                margin: 0 5px 0 0;
                vertical-align: middle;

                &.controllable {
                    cursor: pointer;
                }

                &.active {
                    width: 10px;
                    height: 10px;
                    border-color: transparent;
                    background-color: #C0C0C0;

                    &.primary {
                        background-color: #A0A0A0;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .bar {
            $borderSize: 1px;
            $barHeight: 10px;

            width: 100%;
            height: $barHeight;
            border: $borderSize solid #A0A0A0;
            top: #{($paginationHeight - 10px) / 2};

            .progress {
                height: #{$barHeight - 2 * $borderSize};
                background-color: #A0A0A0;
                position: absolute;
            }
        }

        .pages {
            list-style: none;
            height: 100%;
            margin: 0;
            padding: 0;
            margin-top: #{$paginationHeight / 2};
            border-top: 1px solid grey;
            text-align: center;

            &.locked {
                opacity: 0.3;
            }

            .page {
                display: inline-block;
                height: 100%;
                width: 1.8em;
                text-align: center;
                vertical-align: baseline;
                line-height: 4 / 5 * $paginationHeight;
                font-size: 1.8rem;
                color: grey;
                margin-top: -2px;
                margin-right: 1rem;
                height: 100%;
                border-top: 3px solid transparent;

                &.controllable {
                    cursor: pointer;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.selected {
                    color: grey;
                    border-top: 3px solid grey;
                }
            }
        }
    }

    .directional-nav {
        width: 100%;
        position: absolute;

        &.locked {
            opacity: 0.3;
        }

        &.above {
            top: 0;
        }

        &.top {
            top: 0;
        }

        &.middle {
            top: 50%;
            margin-top: #{$directionalNavSize / -2};
        }

        &.bottom {
            bottom: #{$directionalNavSize * 2};
        }

        &.below {
        }

        .next, .previous {
            z-index: 10;
            height: $directionalNavSize;
            width: 50px;
            cursor: pointer;
            position: absolute;
            user-select: none;
        }
    }
}

.swipe-trigger {
    cursor: move;
}

@mixin gallery-slider() {
  .slider {
    padding-bottom: 0;

    .directional-nav, .pagination {
      padding-top: 10px;
    }

    @include breakpoint($bp-huge) {
      .directional-nav, .pagination {
        padding-top: 0;
      }
    }

    .directional-nav {
      .next {
        opacity: 1;
        background: sprite($icons-large, 'slider-arrow-right-blue');

        &:hover, &:focus, &:active {
          background: sprite($icons-large, 'slider-arrow-right-blue_hover');
        }

        @include breakpoint($bp-huge) {
          right: -70px !important;
        }
      }

      .previous {
        opacity: 1;
        background: sprite($icons-large, 'slider-arrow-left-blue');

        &:hover, &:focus, &:active {
          background: sprite($icons-large, 'slider-arrow-left-blue_hover');
        }

        @include breakpoint($bp-huge) {
          left: -70px !important;
        }
      }
    }
  }
}