$easings: (
  ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1),
  ease-out-qubic: cubic-bezier(0.215, 0.61, 0.355, 1),
  ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
  ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1),
  ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1),
  ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1)
);

$animation-durations: (
  faster: 0.15s,
  default: 0.25s,
  slower: 0.5s,
  slowest: 0.75s
);

$transitions: (
  slowest: animation-duration(slowest) easing(ease-out-quint),
  slower: animation-duration(slower) easing(ease-out-quint),
  default: animation-duration(default) easing(ease-out-quint),
  faster: animation-duration(faster) easing(ease-out-quint),
  navigation: animation-duration(navigation) easing(ease-out-quint),
);

@keyframes appear {
	from {
		transform: translate(-50%, -40%);
		opacity: 0;
	}

	to {
		transform: translate(-50%, -50%);
		opacity: 1;
	}
}
