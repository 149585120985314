$backgroundOverlay: linear-gradient(to top, rgba(255,255,255,0.3) 0%,rgba(255,255,255,0.65) 100%);

.details-image {
  //.titled-background-image {
  //  margin-bottom: 0 !important;
  //
  //  .image-container {
  //    @include breakpoint($bp-medium) {
  //      position: absolute;
  //      top: 0;
  //      left: 0;
  //      right: 0;
  //      bottom: 0;
  //
  //      overflow: hidden;
  //    }
  //  }
  //}

  &__details {
    $root: &;
    $innerPad: 2rem;
    background-color: #FFF;

    &-inner {
      padding: $innerPad;
      padding-bottom: $innerPad * 1.5;
    }

    &-content {
      padding: 0;
    }

    &.has-cta {
      #{$root} {
        &-inner {
          padding-bottom: 115px;
        }
      }

      .button {
        position: absolute;
        bottom: $innerPad;
        left: $innerPad;
        right: $innerPad;
        text-align: center;
      }
    }

    &.striped {
      #{$root} {
        &-inner {
          background-image: $backgroundOverlay, generated-image-url('layout/stripes_light-blue.png');
          background-position: center top;
          background-repeat: no-repeat, repeat;
        }
      }
    }
  }

  @include breakpoint($bp-medium) {
    min-height: 350px;

    //.titled-image {
    //  position: absolute;
    //  top: 0;
    //  bottom: 0;
    //}
    //
    //
    //.details {
    //  .inner {
    //    min-height: 350px;
    //    .quote {
    //      blockquote {
    //        width: auto;
    //        margin-left: 0;
    //        margin-right: 0;
    //      }
    //    }
    //  }
    //}
  }
}

