$colors-box-collection: (
	bg-button: color(vaillant, $colors-brand),
	bg-hover-button: color(vaillant, $colors-brand)
);

.box-collection {
	margin: 0;

	&__teaser {
		height: auto;
	}
}

.box-collection__teaser {
	display: flex;
	flex-direction: column;

	.tag_quote {
		margin: 0;
		width: 100%;
	}
}

.box-collection__entry-heading.box-collection__entry-heading {
	font-size: 2.4rem;
}

.box-collection__teaser-button {
	.button.default {

		&, &.more, &.less {
			background-color: color(bg-button, $colors-box-collection);

			&:hover, &:active, &:focus {
				background-color: color(bg-hover-button, $colors-box-collection);
			}
		}
	}
}
