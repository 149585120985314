.article-teaser {
  $root: &;

  &--grid {
    @include clearfix();

    #{$root} {
      &__image {
        float: left;
        width: 33%;
        margin-right: gutter();

        a, img {
          display: block;
        }

        img {
          max-width: 100%;
        }
      }

      &__content {
        float: right;
        width: 66% - gutter();
        font-size: 1.4rem;

        padding: 0 1rem;

        &-heading {
          position: relative;
          font-size: 1.7rem;
          font-weight: bold;
          color: map_get($colors-neutral, text);

          &:before {
            left: -1.5rem;
            background: sprite($icons-xsmall, 'arrow-small-right-grey');
          }
        }
      }
    }
  }
}
