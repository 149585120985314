.article-teaser {
  $root: &;

  &--leading {
    @extend .article-teaser--full;
    background-color: #FFF;
    border: none;
    padding: 0;

    #{$root} {
      &__image {
        &-heading {

        }

        img {
          user-select: none;
        }
      }

      &__content {
        background-image: none;

        &-heading {

        }
      }

      &__controls {
        &-read-story {

        }
      }
    }
  }
}

.article-teaser-collection__entry {
  &:nth-child(2n+2) {
    .article-teaser--leading {
      .article-teaser {
        &__image {
          order: 1;
        }

        &__content {
          order: 2;
        }
      }
    }
  }
}