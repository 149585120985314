@mixin full-span() {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@mixin intrinsic-ratio($ar) {
    position: relative;
    padding-bottom: 100% / $ar;
    height: 0 !important;

    img {
        position: absolute;
        //top: 0;
        //left: 0;
        //width: 100%;
        //height: 100%;
    }
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);

  or object-fit and object-position:
  @include object-fit(cover, top);

  @see https://github.com/bfred-it/object-fit-images/
*/
@mixin object-fit($fit: fill, $position: null){
    -o-object-fit: $fit;
    object-fit: $fit;
    @if $position {
        -o-object-position: $position;
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } @else {
        font-family: 'object-fit: #{$fit}';
    }

    max-width: none;
}

/* ----------------------------------------------------------------------------
 * Positioning
 * --------------------------------------------------------------------------*/

@mixin center-vertically() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin center-horizontally() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin center() {
    @include center-vertically();
    @include center-horizontally();

    transform: translateX(-50%) translateY(-50%);
    //transform: translate3d(-50%, -50%, 0); // Blurry text in Chrome.
}