$colors-text-image-teaser: (
	bg: color(darkest-grey, $colors-neutral),
	text: #FFF,
	button-label: #FFF,
	bg-button: color(vaillant, $colors-brand)
);

.text-image-teaser {
	background-color: color(bg, $colors-text-image-teaser);
}

.text-image-teaser__content {
	@include content-wrapper();
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.text-image-teaser__heading {
	font-weight: bold;

	// legacy style selectors are too specific
	&.text-image-teaser__heading {
		margin-top: 0;
		margin-bottom: 1em;
		color: color(text, $colors-text-image-teaser);
	}
}

.text-image-teaser__text {
	color: color(text, $colors-text-image-teaser);

	// legacy style selectors are too specific
	a:not(.button) {
		color: color(button-label, $colors-text-image-teaser);
	}
}

.text-image-teaser__image {
	position: relative;

	img {
		width: 100%;
	}
}

.text-image-teaser__link {
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	width: 200px;

	.button.next.default {
		padding-right: 2rem;
		background-color: color(bg-button, $colors-text-image-teaser);

		&:after {
			display: none;
		}
	}

	a {
		color: color(button-label, $colors-text-image-teaser);
	}
}

// Breakpoints
.text-image-teaser {
	$root: &;

	&__image {
		margin-top: 2rem;
	}

	@include breakpoint(bp(text-image-teaser)) {
		&__text {
			@include span(6);
		}

		&__image {
			@include span(last 5);

			margin-top: 0;
		}
	}
}
