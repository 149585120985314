.module_column-layout {
  @include clearfix();
  clear: both;
  float: none;

  margin-top: 3rem;
  margin-bottom: 3rem;

  .module {
    @include reset-content-wrapper();
  }

  @for $i from 3 through 6 {
    > div.span-#{$i} {
      @include span($i of 12);
    }
  }

  .module_column.first, .module_column.left {
    @include first();
  }

  .module_column.last, .module_column.right {
    @include last();
  }
}

.module_column-layout--50-50 {
  @include breakpoint($bp-medium) {
    .module_column {
      @include span(6 of 12);

      &:nth-child(2n+1) {
        @include first();
        clear: both;
      }
      &:nth-child(2n+2) {
        @include last();
      }
    }
  }
}

.module_column-layout--75-25 {
  @include breakpoint($bp-medium) {
    .module_column {
      &:nth-child(2n+1) {
        @include span(first 8 of 12);
        clear: both;
      }
      &:nth-child(2n+2) {
        @include span(last 4 of 12);
      }
    }
  }
}

.module_column-layout--25-75 {
  @include breakpoint($bp-medium) {
    .module_column {
      &:nth-child(2n+1) {
        @include span(first 4 of 12);
        clear: both;
      }
      &:nth-child(2n+2) {
        @include span(last 8 of 12);
      }
    }
  }
}

.module_column-layout--66-33 {
  @include breakpoint($bp-medium) {
    .module_column {
      &:nth-child(2n+1) {
        @include span(first 8 of 12);
        clear: both;
      }
      &:nth-child(2n+2) {
        @include span(last 4 of 12);
      }
    }
  }
}

.module_column-layout--33-66 {
  @include breakpoint($bp-medium) {
    .module_column {
      &:nth-child(2n+1) {
        @include span(first 4 of 12);
        clear: both;
      }
      &:nth-child(2n+2) {
        @include span(last 8 of 12);
      }
    }
  }
}

.module_column-layout--50-25-25 {
  @include breakpoint($bp-medium-only) {
    .module_column {
      &:nth-child(3n+1) {
        @include span(12 of 12);
        clear: both;
      }
      &:nth-child(3n+2) {
        @include span(first 6 of 12);
      }
      &:nth-child(3n+3) {
        @include span(last 6 of 12);
      }
    }
  }
  @include breakpoint($bp-large) {
    .module_column {
      &:nth-child(3n+1) {
        @include span(first 6 of 12);
        clear: both;
      }
      &:nth-child(3n+2) {
        @include span(3 of 12);
      }
      &:nth-child(3n+3) {
        @include span(last 3 of 12);
      }
    }
  }
}

.module_column-layout--25-50-25 {
  @include breakpoint($bp-medium-only) {
    .module_column {
      @include span(4 of 12);

      &:nth-child(3n+1) {
        @include first();
        clear: both;
      }
      &:nth-child(3n+3) {
        @include last();
      }
    }
  }
  @include breakpoint($bp-large) {
    .module_column {
      &:nth-child(3n+1) {
        @include span(first 3 of 12);
        clear: both;
      }
      &:nth-child(3n+2) {
        @include span(6 of 12);
      }
      &:nth-child(3n+3) {
        @include span(last 3 of 12);
      }
    }
  }
}

.module_column-layout--25-25-50 {
  @include breakpoint($bp-medium-only) {
    .module_column {
      &:nth-child(3n+1) {
        @include span(first 6 of 12);
        clear: both;
      }
      &:nth-child(3n+2) {
        @include span(last 6 of 12);
      }
      &:nth-child(3n+3) {
        @include span(12 of 12);
      }
    }
  }

  @include breakpoint($bp-large) {
    .module_column {
      &:nth-child(3n+1) {
        @include span(first 3 of 12);
        clear: both;
      }
      &:nth-child(3n+2) {
        @include span(3 of 12);
      }
      &:nth-child(3n+3) {
        @include span(last 6 of 12);
      }
    }
  }

}

.module_column-layout--25-25-25-25 {
  @include breakpoint($bp-medium) {
    .module_column {
      @include span(3 of 12);

      &:nth-child(4n+1) {
        @include first();
        clear: both;
      }
      &:nth-child(4n+4) {
        @include last();
      }
    }
  }
}

.module_column-layout--33-33-33 {
  @include breakpoint($bp-medium) {
    .module_column {
      @include span(4 of 12);

      &:nth-child(3n+1) {
        @include first();
        clear: both;
      }
      &:nth-child(3n+3) {
        @include last();
      }
    }
  }
}