$colors-footer: (
	bg: rgba(#323232, 1)
);

.site-footer {
	background-color: color(bg, $colors-footer);
	border-top: 2px solid #FFF;
}

.site-footer__logo {
	.logo__link {
		width: 13rem;
	}
}

.site-footer__content {
	@include content-wrapper();

	padding: 2rem;
}

.site-footer {
	.compact-navigation-bar {
		&__navigation {
			display: none;
		}

		&__logo {
			margin-top: 0;
		}
	}
}
