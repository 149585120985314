
@mixin button($icon: null, $iconPosition: 'right', $iconColor: 'white',
			  $bgColor: map-get($colors-primary, blue),
			  $bgColorHover: map-get($colors-primary, dark-blue)) {


  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;

  @extend %wf-display;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;

  &:hover, &:focus, &:active {
    text-decoration: none;
  }

	@if ($icon != null) {
		$iconSize: 26px;
		$iconOffsetX: $iconSize / 2;
		$iconPadding: $iconSize + 2 * $iconOffsetX;
		$iconName: $icon + "-" + $iconColor;

		&:before, &:after {
			background: sprite($icons-small, $iconName);
			background-repeat: no-repeat;
			position: absolute;
			top: 50%;
			margin-top: -$iconSize / 2;
		}

		@if ($iconPosition == 'right') {
			padding-right: $iconPadding;

			&:after {
				@include pseudo-element-defaults($iconSize, $iconSize);
				right: $iconOffsetX;
			}
		} @else {
			padding-left: $iconPadding;

			&:before {
				@include pseudo-element-defaults($iconSize, $iconSize);
				left: $iconOffsetX;
			}
		}
	}
}

@mixin default-button($icon: null, $iconPosition: 'right', $iconColor: 'white',
				$bgColor: map-get($colors-primary, blue),
				$bgColorHover: map-get($colors-primary, dark-blue)) {
  @include button($icon, $iconPosition, $iconColor, $bgColor, $bgColorHover);

  background-color: $bgColor;
  color: #FFF;

	transition: background-color $transition-fast;

  padding-top: 16px;
  padding-bottom: 16px;

	&:before, &:after {
		transition: transform $transition-fast;
	}

  &:hover, &:focus, &:active {
    background-color: $bgColorHover;

    &:before, &:after {
      @if ($iconPosition == 'right') {
        transform: translate(-5px);
      } @else {
				transform: translate(5px);
      }
    }
  }

	@if ($icon != null) {
		@if ($iconPosition == 'right') {
			padding-left: 20px;
		} @else {
			padding-right: 20px;
		}
	} @else {
		padding-right: 20px;
		padding-left: 20px;
	}
}

@mixin white-default-button($icon, $iconPosition: 'right') {
  @include default-button($icon, $iconPosition, 'blue', #FFF, map-get($colors-neutral, 'light-grey'));

  color: map-get($colors-primary, 'dark-blue');
}

@mixin secondary-button($icon, $iconPosition: 'right', $iconColor: 'blue') {
  @include button($icon, $iconPosition, $iconColor, transparent, transparent);

  color: map-get($colors-primary, blue);

  span {
    display: inline-block;

    padding-top: 14px;
    padding-bottom: 12px;
    border-bottom: 2px solid map-get($colors-primary, blue);
  }

  &:hover, &:focus, &:active {
    color: map-get($colors-primary, dark-blue);
    span {
      border-bottom-color: map-get($colors-primary, dark-blue);
    }
  }

  $iconSize: 26px;
  $iconOffsetX: $iconSize / 2;
  $iconPadding: $iconSize + $iconOffsetX;
  $iconName: $icon + "-" + $iconColor;

  @if ($iconPosition == 'right') {
    padding-right: $iconPadding;

    &:after {
      right: 0;
    }
  } @else {
    padding-left: $iconPadding;

    &:before {
      left: 0;
    }
  }
}

@mixin white-secondary-button($icon, $iconPosition: 'right') {
  @include secondary-button($icon, $iconPosition, 'white');

  color: #FFF;

  span {
    border-bottom-color: #FFF;
  }

  &:hover, &:focus, &:active {
    color: map-get($colors-neutral, light-grey);

    span {
      border-bottom-color: map-get($colors-neutral, light-grey);
    }
  }
}

.read-more {
  $size: 16px;

  display: inline-block;
  position: relative;
  color: map-get($colors-primary, blue);

  text-decoration: none;
  padding-left: $size;

  &:hover, &:focus, &:active {
    text-decoration: none;
  }

  &:before {
    @include pseudo-element-defaults($size, $size);
    // TODO: sprite
    background: sprite($icons-xsmall, arrow-light-right-blue);
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -$size / 2;
    left: 0;
  }
}

.button {
  &.default {
    $bgColor: map-get($colors-primary, blue);
    $bgColorHover: map-get($colors-primary, dark-blue);

    background-color: $bgColor;
    color: #FFF;
    outline: none;
    border: none;
    -webkit-appearance: none;
    cursor: pointer;

    @extend %wf-display;
    position: relative;
    text-align: left;
    font-size: 1.6rem;
    text-transform: uppercase;
    text-decoration: none;

    padding: 1rem;

    &:hover, &:focus, &:active {
      background-color: $bgColorHover;
      text-decoration: none;
    }
  }

  &.more {
    &.default {
      @include default-button('plus');
    }

    &.default.white {
      @include white-default-button('plus');
    }
    &.secondary {
      @include secondary-button('plus');
    }

    &.secondary.white {
      @include white-secondary-button('plus');
    }

    span.less { display: none; }
  }

  &.less {
    &.default {
      @include default-button('minus');
    }
    &.default.white {
      @include white-default-button('minus');
    }
    &.secondary {
      @include secondary-button('minus');
    }
    &.secondary.white {
      @include white-secondary-button('minus');
    }

    span.more { display: none; }
  }

  &.next {
    &.default {
      @include default-button('arrow-light-right');
    }
    &.default.white {
      @include white-default-button('arrow-light-right');
    }
    &.secondary {
      @include secondary-button('arrow-light-right');
    }

    &.secondary.white {
      @include white-secondary-button('arrow-light-right');
    }
  }

  &.previous {
    &.default {
      @include default-button('arrow-light-left', 'left');
    }
    &.default.white {
      @include white-default-button('arrow-light-left', 'left');
    }
    &.secondary {
      @include secondary-button('arrow-light-left', 'left');
    }

    &.secondary.white {
      @include white-secondary-button('arrow-light-left', 'left');
    }
  }

  &.center {
    text-align: center;
  }
  &.right {
    text-align: center;
  }
  &.center, &.right {
    a { text-align: left; }
  }

  &.issue {
    //margin-top: 5rem;
    //text-align: center;
    //padding: 0 2rem;

    text-transform: uppercase;
    &:before {
      @include pseudo-element-defaults(50px, 50px);
      display: block;
      background-repeat: no-repeat;
      margin: 0 auto;
    }

    span {
      display: block;
      @extend %wf-display;
      font-size: 2rem;
      margin-top: 0.5em;
    }

    &.download:before {
      background: sprite($icons-large, 'download');
    }

    &.read:before {
      background: sprite($icons-large, 'read');
    }

    @include breakpoint($bp-medium) {
      text-align: left;
      &:before {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1em;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        margin-top: 0;
      }
    }
  }
}


.button {

}

.button {
  &.-filled {

  }

  &.-bordered {

  }

  &.-underlined {

  }

  &.-icon-right {

  }

  &.-icon-left {

  }
}
