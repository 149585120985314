$colors-cta: (
	bg-primary: map-get($colors-primary, blue),
	bg-hover-primary: map-get($colors-primary, dark-blue),

	bg-seconday: color(vaillant, $colors-brand),
	bg-hover-seconday: color(vaillant, $colors-brand),
);

@mixin cta($bgColor: map-get($colors-cta, bg-primary), $bgColorHover: map-get($colors-cta, bg-hover-primary)) {
	@include default-button(null, null, null, $bgColor, $bgColorHover);
}

.tag_cta {
	@include cta(map-get($colors-cta, bg-seconday), map-get($colors-cta, bg-hover-seconday));
}
