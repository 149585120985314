::selection {
    //color: color(neutral-lightest);
  background: map-get($colors-secondary, light-blue);
  text-shadow: none;
}

body {
    @extend %wf-copy;

    color: map-get($colors-neutral, text);
    font-size: font-size(copytext);
    //line-height: line-height(copytext);
}

p {
  line-height: 1.5em;
  margin-top: 0;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }

  small {
    line-height: 1;
  }
}

a {
  @include unstyled-link();
  cursor: pointer;
}


dl {
  font-size: 1.4rem;
  line-height: 1.5em;

  dt {
    font-weight: bold;
    &:after { content: ": " };
  }

  dd {
    margin: 0;
    margin-bottom: 0.5em;
    padding: 0;
  }
}

.compact dl {
  dt:before {
    content: "";
    display: block;
  }
  dt, dd {
    display: inline;
  }
}

.todo {
  color: map-get($colors-brand, saunier-duval);
  font-weight: bold;
  margin: 2em 0;
}

h1, h2, h3, h4, h5, h6 {
  @extend %wf-headings;
  margin: 0;
  font-weight: normal;
}

.upper {
  text-transform: uppercase;
}

.rich-text {
	&.-two-columns {
		columns: 2;
		column-width: 40ch;
		column-gap: 2em;
	}
}

.content {
	$content-font-size: 1.6rem;
	font-size: $content-font-size;

	h1, h2 {
		@extend %wf-headings;

		color: map-get($colors-primary, blue);
		font-size: 4rem;
		margin-bottom: 0.5em;
	}

	h3 {
		color: map-get($colors-primary, blue);
		font-size: 2rem;
		margin-top: 2em;
		margin-bottom: 0.5em;
	}

	h4 {
		font-size: 2rem;
		margin-top: 2em;
		margin-bottom: 0.5em;
	}

	h5 {
		font-size: 1.8rem;
		color: map-get($colors-primary, blue);
		margin-top: 1em;
		margin-bottom: 0.5em;
		font-weight: bold;
	}

	h6 {
		font-size: $content-font-size;
		margin-top: 1em;
		margin-bottom: 0.5em;
		font-weight: bold;
	}

	a:not(.button) {
		color: map-get($colors-primary, blue);

		&:hover, &:focus, &:active {
			text-decoration: underline;
		}
	}

	ul:not([class]), ol:not([class]) {
		li {
			margin-bottom: 0.7em;
			line-height: 1.5em
		}
	}

	table:not([class]) {
		margin: 2rem 0;

		td, th {
			padding: 0.5em;
		}
	}
}
