$colors-heading: (
	bg: color(vaillant, $colors-brand)
);

.tag_heading {
	margin-left: 0.3em;

	&:not(.no-break) {
		max-width: 30ch;
	}

	&__text {
		@include background-heading(color(vaillant, $colors-brand), #FFF);
	}
}
