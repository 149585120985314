.article-teaser-collection {
  margin-bottom: 2rem;
  margin-bottom: 5rem;

  &__heading {
    font-size: 4rem;
    margin-bottom: 1rem;
    color: $categoryColor;
  }

  &__entries {
    @include unstyled-list();
    @include clearfix();
  }

  &__entry {

  }
}

.article-teaser-collection {
  $root: &;

  &--leading {
    @include content-wrapper();
    padding: 0;
    margin-bottom: 0;

    .article-teaser {
      margin: 0;
    }

    #{$root} {

      @include breakpoint($bp-small-only) {
        &__entries {
          background-color: #FFF;
        }
      }

      &__entries {
        position: relative;
        @include gallery-slider();

        .slider {
          padding-bottom: 0;
        }
      }
    }
  }

  &--expandable {
    #{$root}__entry {
      padding-top: 1rem;
      margin-bottom: 2rem;

      $rows: (
        $bp-small-only: 2,
        $bp-medium: 2
      );

      @each $bp, $numElements in $rows {
        @include breakpoint($bp) {
          @include rowify($numElements);
        }
      }
    }
  }

  &--slider {
    #{$root}__entries {
      position: relative;
      @include gallery-slider();
      user-select: none;

      .slider {
        .pagination {
          .pages {
            border-top: 1px solid map-get($colors-neutral, dark-grey);

            .page {
              @extend %wf-display;
              color: map-get($colors-neutral, dark-grey);

              &.selected {
                color: map-get($colors-neutral, darkest-grey);
                border-top: 3px solid map-get($colors-neutral, darkest-grey);
              }
            }
          }
        }

        .directional-nav {
          .previous {
            background: sprite($icons-large, 'arrow-round-left');

            @include breakpoint($bp-huge) {
              left: 50px !important;
            }


            &:hover, &:focus, &:active {
              background: sprite($icons-large, 'slider-arrow-left-blue_hover');
            }
          }

          .next {
            background: sprite($icons-large, 'arrow-round-right');

            @include breakpoint($bp-huge) {
              right: 50px !important;
            }

            &:hover, &:focus, &:active {
              background: sprite($icons-large, 'slider-arrow-right-blue_hover');
            }
          }
        }
      }
    }
  }

  &--full {

  }

  &--grid {
    #{$root}__entry {
      padding-top: 1rem;
      margin-bottom: 2rem;

      $rows: (
        $bp-small-only: 2,
        $bp-medium-only: 2,
        $bp-large: 3
      );

      @each $bp, $numElements in $rows {
        @include breakpoint($bp) {
          @include rowify($numElements);
        }
      }
    }
  }
}