.icon-container {
  display: inline-block;

  font-size: 1.6rem;
  color: map-get($colors-neutral, dark-grey);
}

.icon-container {
  &__link {
    color: map-get($colors-primary, blue);

    transition: color $transition-default;

    &:hover {
      color: map-get($colors-primary, dark-blue);
    }
  }

  &__icon {
    @include size(1em);
  }

  &__label {

  }
}

.icon-container {
  $root: &;

  &.has-text {
    #{$root} {
      &__link {
        display: flex;
        align-items: center;
      }

      &__label {
        margin-left: 0.75rem;
      }
    }
  }
}