$colors-header: (
	bg: #FFF,
	bg-inverted: rgba(#323232, 1),
	color-inverted: #FFF,
	border-color: #000,
	logo-push-digital: #FFF
);

.site-header {
	background-color: color(bg, $colors-header);
	padding-top: 2rem;
	border-bottom: 2px solid color(border-color, $colors-header);

	@include breakpoint(bp(header)) {
		padding-bottom: 3rem;
	}
}

.site-header__content {
	@include content-wrapper();
}

.site-header--inverted {
	background-color: color(bg-inverted, $colors-header);
	color: color(color-inverted, $colors-header);
	border-bottom: none;
}


.site-header {
	.logo-vaillant,
	.logo-push-digital {
		margin: auto 0;
	}

	.logo-vaillant {
		.logo__link {
			width: 13rem;
		}

		@include breakpoint(bp(header)) {
			margin: 0 0 4rem;
		}
	}

	.logo-push-digital {
		justify-content: flex-start;
		fill: color(logo-push-digital, $colors-header);
	}

	.logo-push-digital {
		height: 30px;

		@include breakpoint(bp(header)) {
			height: 60px;
			margin-bottom: 1.7em;
		}
	}

	.hero-headline {
		margin-bottom: 0.5em;
	}
}

.site-header {
	.logo-vaillant {
		grid-area: header-logo-vaillant;
	}

	.logo-campaign {
		grid-area: header-logo-campaign;
		align-self: end;
		justify-self: end;
	}

	.logo-push-digital,
	.hero-headline {
		grid-area: header-headline;
	}

	.main-navigation {
		grid-area: header-nav;
	}

	&__content {
		display: grid;
		grid-template-areas:
			"header-headline header-logo-vaillant"
			"header-nav header-nav";
		grid-template-columns: 1fr auto; // IE11 fallback
		//grid-template-columns: minmax(24rem, 1fr) minmax(4rem, auto);
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto auto;
		gap: 2rem;

		.logo-campaign {
			display: none;
		}

		@include breakpoint(bp(header)) {
			grid-template-areas:
			"header-logo-vaillant header-logo-vaillant"
			"header-headline header-logo-campaign"
			"header-nav header-nav";

			.logo-vaillant {
				grid-area: header-logo-campaign;
				margin-bottom: auto;
			}

			.logo-campaign {
				display: block;
			}
		}
	}
}

.sticky-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;

	background-color: color(bg-inverted, $colors-header);
}

.sticky-header__content {
	@include content-wrapper();
	padding: 2rem;
}

// Sticky header state
.sticky-header {
	transform: translateY(-100%);
	transition: transform transition(default);

	&.is-visible {
		transform: translateY(0);
	}
}
