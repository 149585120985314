.media-collection {
  @include content-wrapper();
  padding: 0;
}

.media-collection {
  &__entries {
    @include unstyled-list();
    @include gallery-slider();
    position: relative;
  }

  &__entry {

  }

  &__medium {
    $root: &;

    &-image {
      .figure {
        position: relative;
        margin: 0;

        &__image {
          width: 100%;
          max-width: 100%;
          height: auto;
        }

        &__caption {
          @extend %wf-display;

          font-size: 1.5rem;
          line-height: 1.3em;
          color: map_get($colors-neutral, darker-grey);

          padding: 1.5rem;
          background-color: #FFF;

          @include breakpoint($bp-medium) {
            //position: absolute;
            max-width: 50%;
            //left: 10px;
            //right: 10px;
            //bottom: 10px;
          }
          @include breakpoint($bp-large) {
            max-width: 33%;
          }
        }
      }

      &-details {
        background: #FFF;
        padding: 1px;
      }
    }

    &--details-image {
      $innerPad: 2rem;

      .figure {
        display: block;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          @include object-fit(cover);
        }
      }

      #{$root} {
        &-image {
        }

        &-image-details {
          background-color: #FFF;

          padding: $innerPad;
          padding-bottom: $innerPad * 1.5;

          .tag_quote {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
        }
      }


      @include breakpoint($bp-medium) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        min-height: 350px;

        .figure {
          width: span(6 of 12);
        }

        #{$root} {
          &-image-details {
            width: span(6 of 12);
          }
        }
      }

      @include breakpoint($bp-large) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        min-height: 350px;

        .figure {
          width: span(8 of 12);
        }

        #{$root} {
          &-image-details {
            width: span(4 of 12);
          }
        }
      }
    }

    &-video {
			.video-container {
				width: 100%;
			}
    }
  }
}
