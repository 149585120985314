.box-collection {

}

@mixin box-collection-expanded-background() {
  background-color: #FFF;
  background-image: generated-image-url('layout/stripes_01.png');
  background-repeat: repeat;
}

@mixin box-collection-layout-50-50() {
  width: span(6 of 12);
  margin-right: gutter();

  .box-collection__details {
    width: span(12 of 6);
  }

  &:nth-child(2n+1) {
    .box-collection__teaser {
      background-position: left bottom;
    }

    .box-collection__details {
      background-position: left top;
      left: 0;
    }
  }

  &:nth-child(2n+2) {
    margin-right: 0;

    .box-collection__teaser {
      background-position: right bottom;
    }

    .box-collection__details {
      background-position: right top;
      right: 0;
    }
  }
}

@mixin box-collection-layout-33-33-33() {
  width: span(4 of 12);
  margin-right: gutter();
  $content-width: span(12 of 4);

  .box-collection__details {
    width: $content-width;
  }

  &:nth-child(3n+1) {
    .box-collection__teaser {
      background-position: left bottom;
    }

    .box-collection__details {
      background-position: left top;
      left: 0;
    }
  }

  &:nth-child(3n+2) {
    .box-collection__teaser {
      background-position: center bottom;
    }

    .box-collection__details {
      background-position: center top;
      left: -($content-width / 2) + 50%;
    }
  }

  &:nth-child(3n+3) {
    margin-right: 0;

    .box-collection__teaser {
      background-position: right bottom;
    }

    .box-collection__details {
      background-position: right top;
      right: 0;
    }
  }
}

.box-collection {
  margin-bottom: 4rem;

  $root: &;

	&__entry {
		@include breakpoint($bp-mobile-only) {
			width: 100%; // IE needs an explicitely set width
		}
	}

  &--layout-50-50 {
    #{$root} {
      &__entry {
        @include breakpoint($bp-medium) {
          @include box-collection-layout-50-50();
        }
      }
    }
  }

  &--layout-33-33-33 {
    #{$root} {
      &__entry {
        @include breakpoint($bp-medium-only) {
          @include box-collection-layout-50-50();
        }

        @include breakpoint($bp-large) {
          @include box-collection-layout-33-33-33();
        }
      }
    }
  }
}

.box-collection {
  position: relative;

  &__entries {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @include clearfix();

    list-style: none;
    margin: 0;
    padding: 0;

    .box-collection__entry {
      margin-bottom: 0;
      margin-top: 2rem;
    }
  }

  &__entry {
    position: relative;
    margin: 0;
    padding: 0;

    &-header {

    }
  }

  &__teaser {
    position: relative;
    height: 100%;
    z-index: 3;
    border: 1px solid map_get($colors-neutral, light-grey);

    // IE11 Bugfix
    // @see https://github.com/philipwalton/flexbugs/issues/75#issuecomment-161800607
    &-image {
      min-height: 1px;
    }

    &-content {
      padding: 3rem 1.5rem 2rem;

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }

		&-button {
			text-align: center;
			margin-top: auto;
			margin-bottom: 4rem;
		}
  }

  &__details {
    position: absolute;
    top: 100%;
    margin-top: -1px;
    z-index: 2;
    overflow: hidden;

    @include box-collection-expanded-background();

    &.collapsed {
      height: 0;
    }

    &-content {
      border: 1px solid map_get($colors-neutral, light-grey);
      padding: 1.5rem;
      padding-bottom: 4rem;
    }
  }
}



.box-collection {
  $root: &;

  &.is-expandable {
    #{$root} {
      &__teaser {
        display: flex;
        flex-direction: column;;
        justify-content: space-between;

        &-content {
          margin-top: 0;
          margin-bottom: 3rem;
          padding-bottom: 0;
        }
      }

      &__entry.open {
        z-index: 10;

        #{$root} {
          &__teaser {
            border-bottom: none;
            @include box-collection-expanded-background();
          }
        }
      }
    }
  }
}
