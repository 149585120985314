$colors-strategy: (
	bg-cta: color(vaillant, $colors-brand),
	heading: color(vaillant, $colors-brand)
);

.page-strategy {
	.module_text-grid {
		// TODO: tag with class
		&:nth-child(2) .rich-text {
			h2 {
				margin-top: 2em;
				margin-bottom: 1em;
				font-size: 2.1rem;

				font-weight: bold;
				color: inherit;
				text-transform: uppercase;
			}
		}
	}

	.rich-text {
		h2 {
			text-transform: uppercase;
		}

		h3 {
			text-transform: uppercase;
		}
	}

	.tag_cta {
		@include default-button(
				'arrow-light-right', 'right', 'white',
				color(bg-cta, $colors-strategy),
				color(bg-cta, $colors-strategy)
		);
	}
}
