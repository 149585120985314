$colors-main-navigation: (
	bg: #FFF,
	border: #000,
	text: #FFF,
	bg-entry: color(darkest-grey, $colors-neutral),
	bg-entry-active: color(light-blue, $colors-secondary),
	bg-entry-hover: color(vaillant, $colors-brand),
	bg-inverted: rgba(#323232, 1),
	text-inverted: #000,
	text-hover-inverted: #FFF,
	bg-entry-inverted: #FFF,
	bg-entry-hover-inverted: color(vaillant, $colors-brand),
);

.main-navigation {
	user-select: none;
}

$entry-margin: 1.2em;

.main-navigation__entries {
	@include unstyled-list();
	display: flex;
	flex-wrap: wrap;

	$min-font: 1.4rem;
	$max-font: 1.6rem;

	font-size: $min-font;
	font-size: clamp($min-font, 2vw, $max-font);
	letter-spacing: 0.05em;

	margin-bottom: -1 * $entry-margin;
}

.main-navigation__entry {
	display: flex;
	margin-right: $entry-margin;
	margin-bottom: $entry-margin;

	&-link {
		display: flex;
		align-items: center;
		background-color: color(bg-entry, $colors-main-navigation);
		color: color(text, $colors-main-navigation);
		padding: 0.25em;
		text-align: center;
		text-transform: uppercase;
	}

	&-link:hover {
		background-color: color(bg-entry-hover, $colors-main-navigation);
	}

	&.is-active &-link {
		background: transparent;
		color: color(text, $colors-main-navigation);
		outline: 1px solid currentColor;

		&:hover {
			color: color(darkest-grey, $colors-neutral);
			outline: none;
		}
	}
}

// variant: inverted
.main-navigation {
	$root: &;

	&--inverted {

		#{$root} {
			&__entry-link {
				background-color: color(bg-entry-inverted, $colors-main-navigation);
				color: color(text-inverted, $colors-main-navigation);

				&:hover {
					background-color: color(bg-entry-hover-inverted, $colors-main-navigation);
					color: color(text-hover-inverted, $colors-main-navigation);
				}
			}
		}
	}
}
