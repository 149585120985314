@function rem($px) {
    @return 1rem * ($px / $root-font-size);
}

@function em($px-value, $base-font-size: 16px) {
    @return 1em * ($px-value / $base-font-size);
}

@function initial-rem($pxSize) {
  $remSize: $pxSize / 16px;
  @return $remSize * 1rem;
}

/**
 * Get variant class name, e.g.
 * $class-name: button
 * $modifier: primary
 * --> result: button--primary
 */
@function variant-name($class-name, $modifier: '') {
    @if $modifier != '' {
        $class-name: $class-name + '--' + $modifier;
    }
    @return $class-name;
}

/**
 * Returns the breakpoint value.
 */
@function bp($breakpoint-name, $min-width: true) {
    $bp-value: map-get($breakpoints, $breakpoint-name);

    @if $min-width != true {
        $bp-value: $bp-value - em(1px);
    }

    @return $bp-value;
}

@function layout-bp($breakpoint-name, $min-width: true) {
    $bp-value: map-get($layout-breakpoints, $breakpoint-name);

    @if $min-width != true {
        $bp-value: $bp-value - em(1px);
    }

    @return $bp-value;
}

@function spacing($n) {
    @return nth($spacings, $n);
}

@function spacing-context($key, $map) {
    @return map-get($map, $key);
}


@function layer($name) {
    @return map-get($layers, $name);
}

@mixin size($width, $height: null) {
    $height: if($height == null, $width, $height);

    width: $width;
    height: $height;
}


@mixin hover-active-states() {
    &:hover, &:focus, &:active {
        @content;
    }
}

@mixin ie-only() {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}

/* ----------------------------------------------------------------------------
 * Pseude elements, background images
 * --------------------------------------------------------------------------*/

@mixin hide-text($text-indent) {
  overflow: hidden;
  white-space: nowrap;
  text-indent: 2 * $text-indent;
}

@mixin pseudo-element-defaults($width: 0, $height: 0) {
  content: ' ';
  display: inline-block;
  width: $width;
  height: $height;
}
