$colors-anchor-navigation: (
	bg: #FFF,
	icon: color(vaillant, $colors-brand),
	text: color(vaillant, $colors-brand)
);
$icon-size: rem(14px);
$content-padding: rem(16px);

.anchor-navigation {
	z-index: map-get($layers, anchor-navigation);
	width: rem(240px);

	background-color: color(bg, $colors-anchor-navigation);
	color: color(text, $colors-anchor-navigation);
}

// shadow
.anchor-navigation {
	$shadow: 0 0 12px 2px rgba(0,0,0,0.2);
	$drop-shadow: 0 0 12px rgba(0,0,0,0.2);

	&,
	&:not(.is-open) .anchor-navigation__controls {
		box-shadow: $shadow;
	}

	@supports (filter: drop-shadow($drop-shadow)) {
		box-shadow: none;
		filter: drop-shadow($drop-shadow);
	}
}

.anchor-navigation__controls {
	display: flex;

	$controls-size: 3 * $icon-size;

	justify-content: center;
	align-items: center;

	background-color: color(bg, $colors-anchor-navigation);

	position: absolute;
	right: -1 * $controls-size;
	top: 0;

	width: $controls-size;
	height: $controls-size;
}

.anchor-navigation__control {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	cursor: pointer;
	border: none;
	outline: none;
	background-color: color(bg, $colors-anchor-navigation);

	&:focus {
		border: 1px dotted color(border, $colors-anchor-navigation);
	}

	&-icon {
		color: color(icon, $colors-anchor-navigation);

		.icon-container__icon {
			width: $icon-size;
			height: $icon-size;
			min-width: $icon-size;
			min-height: $icon-size;
			transition: transform transition(default);
		}
	}
}

.anchor-navigation__content {
	padding: $content-padding;
}

.anchor-navigation__entries {
	@include unstyled-list();
}

.anchor-navigation__entry {
	margin-top: 0.5em;

	&:first-child {
		margin-top: 0;
	}

	&-link {
		display: block;
		padding: 0.25em;

		.icon-container {
			&__link {
				color: color(text, $colors-anchor-navigation);
			}

			&__icon {
				width: $icon-size;
				height: $icon-size;
				min-width: $icon-size;
				min-height: $icon-size;
			}

			&__label {
				font-size: 1.4rem;
			}
		}
	}
}

// State: open
.anchor-navigation {
	transform: translateX(-100%);
	transition: transform transition(default);

	&.is-open {
		transform: translateX(0);

		.anchor-navigation__control {
			transform: rotate(180deg);
		}
	}
}

// State: sticky
.anchor-navigation {
	position: absolute;

	&.is-sticky {
		position: fixed;
	}
}
