.text-grid {
	$text-grid-gap: 2em;

	// IE fallback (50/50 only)
	@include breakpoint(bp(sm)) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		> * {
			width: calc(50% - #{$text-grid-gap / 2});
		}

		&-layout-50-50 {
			grid-template-columns: 1fr 1fr;
		}
	}

	@supports (grid-gap: 2rem) {
		display: grid;
		grid-gap: 0 $text-grid-gap;

		@include breakpoint(bp(sm)) {
			grid-gap: $text-grid-gap/2 $text-grid-gap;

			> * {
				width: auto;
				margin: initial;
			}

			&-layout-50-50 {
				grid-template-columns: 1fr 1fr;
			}

			&-layout-75-25 {
				grid-template-columns: 3fr 1fr;
			}

			&-layout-25-75 {
				grid-template-columns: 1fr 3fr;
			}

			&-layout-66-33 {
				grid-template-columns: 2fr 1fr;
			}

			&-layout-33-66 {
				grid-template-columns: 1fr 2fr;
			}

			&-layout-33-33-33 {
				grid-template-columns: 1fr 1fr 1fr;
			}

			&-layout-50-25-25 {
				grid-template-columns: 2fr 1fr 1fr;
			}

			&-layout-25-50-25 {
				grid-template-columns: 1fr 2fr 1fr;
			}

			&-layout-25-25-50 {
				grid-template-columns: 1fr 1fr 2fr;
			}

			&-layout-25-25-25-25 {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
		}
	}
}

.text-grid .rich-text {
	ul, ol {
		margin-left: 0;
		padding-left: 2ch;
	}
}
