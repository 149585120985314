.content {
	h1, h2, h3, h4, h5, h6 {
		color: color(vaillant, $colors-brand);
	}
}

::selection {
	background: color(vaillant, $colors-brand);
	color: #FFF;
}
