// Legacy
@import "legacy/legacy";

// Vaillant Digital microsite
@import "base/typography";

@import "utils/animations";
@import "utils/typography";

@import "settings/animations";
@import "settings/layers";

@import "elements/organisms/animated-hero-headline.scss";
@import "elements/organisms/anchor-navigation";
@import "elements/organisms/box-collection";
@import "elements/organisms/compact-navigation-bar";
@import "elements/organisms/dialog";
@import "elements/organisms/hotspot-image";
@import "elements/organisms/main-navigation";
@import "elements/organisms/text-grid";
@import "elements/organisms/text-image-teaser";
@import "elements/organisms/text-video";
@import "elements/organisms/interview-teaser";

@import "tags/cta";
@import "tags/heading";
@import "tags/icon-link";
@import "tags/image";
@import "tags/intro";
@import "tags/quote";

@import "modules/module";
@import "modules/inline-page";
@import "modules/text-grid";
@import "modules/text-image-teaser";
@import "modules/text-video";

@import "global/footer";
@import "global/header";
@import "global/layout";
@import "global/logo";

@import "templates/article";
@import "templates/category";

@import "pages/strategy";

html {
	scroll-behavior: smooth;
}
