.hero-headline {
	$min-width: rem(320px);
	$max-width: rem(768px);
	$min-font: 4rem;
	$max-font: 6.8rem;

	display: grid;
	grid-template-rows: auto auto;

	font-size: $min-font;
	font-size: clamp($min-font, 10vw, $max-font);
	font-weight: bold;
	line-height: 0.9;
	text-transform: uppercase;

	// required for line-height < 1
	> span {
		display: block;
	}

	@include ie-only() {
		@include breakpoint(bp(md)) {
			font-size: $min-font + ($max-font - $min-font) / 2;
		}

		@include breakpoint(bp(xl)) {
			font-size: $max-font;
		}
	}
}

.hero-headline__word {
	visibility: hidden;
	opacity: 1;
	grid-column: 1;
	grid-row: 2;
	color: inherit;

	$max-words: 10;

	animation-name: swap-words;
	animation-iteration-count: infinite;

	animation: swap-words
		var(--animated-headline-duration)
		steps(var(--animated-headline-words))
		infinite;

	@for $i from 1 through $max-words {
		&:nth-child(#{$i}) {
			animation-delay: calc(var(--animated-headline-interval)  * #{$i - 2});
		}
	}
}
