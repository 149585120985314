$font-dir: "../fonts/";

/* ----------------------------------------------------------------------------
 * Vaillant Type
 * --------------------------------------------------------------------------*/

$filename-prefix: "compatilfactltpro";
$font-stack-sans-serif: "CompatilFact", "PT Sans", Roboto, "Segeo UI", Arial, Helvetica, sans-serif;

@font-face {
	font-family: 'CompatilFact';
	src: url($font-dir + $filename-prefix + '-rg.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'CompatilFact';
	src: url($font-dir + $filename-prefix + '-bd.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}


%wf-CompatilFact {
    font-family: $font-stack-sans-serif;
}

%wf-copy {
  font-family: Arial, Helvetica, sans-serif;
}

%wf-headings {
  @extend %wf-CompatilFact;
}

%wf-display {
  @extend %wf-CompatilFact;
}

%wf-titles {
  @extend %wf-CompatilFact;
}


/* ----------------------------------------------------------------------------
 * Icons
 * --------------------------------------------------------------------------*/

@font-face {
  font-family: 'vaillant-life-icons';
  src: url($font-dir + 'vaillant-life-icons.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

%vaillaint-life-icon {
  font-family: "vaillant-life-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

%icon-rss {
  @extend %vaillaint-life-icon;
  content: '\e800';
}
