@mixin background-heading($background-color, $color, $offset-x: 0, $line-height: 1.2) {
	display: inline;
	padding: 0.625rem 0 0.375rem;

	font-weight: bold;
	text-transform: uppercase;
	line-height: $line-height;
	letter-spacing: -0.025em;

	color: $color;
	background-color: $background-color;
	box-shadow: 1rem 0 0 $background-color, -1rem 0 0 $background-color;
	box-decoration-break: clone;
}

// Doesn't work properly in IE11.
//@mixin background-heading($background-color, $color, $offset-x: 0, $line-height: 1.5) {
//	color: $color;
//	display: inline;
//	line-height: $line-height;
//
//	left: $offset-x + 0.65em;
//
//	padding: 0.4em 0 0.4em 0;
//	background-color: $background-color;
//	box-shadow: 0.6em 0 0 $background-color, -0.65em 0 0 $background-color;
//	box-decoration-break: clone;
//	text-transform: uppercase;
//}
