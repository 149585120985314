$colors-interview-teaser: (
	bg-interviewee: map-get($colors-brand, vaillant),
	text-interviewee: #FFF
);

.interview-teaser {
	&__heading[class] {
		font-size: 2.4rem;
		margin-bottom: 3rem;
	}

	&__content {
		@include breakpoint($bp-medium) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto auto;
			grid-template-areas:
				"interview-teaser-image interview-teaser-image"
				"interview-interviewee1 interview-interviewee2";
			grid-gap: 3rem 5.5rem;
		}
	}

	// explicit placement for IE11 / Edge Legacy
	&__figure-wrapper {
		grid-area: interview-teaser-image;
	}

	&__interviewee {
		&-1 { grid-area: interview-interviewee1 }
		&-2 { grid-area: interview-interviewee2 }
	}

	&__figure {
		margin: 0;
		margin-bottom: 3rem;

		@include breakpoint($bp-medium) {
			display: flex;
			flex-shrink: 1;
			flex-grow: 0;
		}

		img {
			width: 100%;
		}
	}

	&__image-wrapper {
	}

	&__image-caption {
		font-weight: bold;
		color: inherit;
		margin: 0;
		line-height: 1.33;

		@include breakpoint($bp-medium) {
			flex-shrink: 0;
			width: 30ch;
			margin-left: 2rem;
		}
	}

	&__interviewee {
		//max-width: 48ch;

		.interviewee {
			@extend %wf-headings;

			display: inline-block;
			text-transform: uppercase;
			background-color: color(bg-interviewee, $colors-interview-teaser);
			color: color(text-interviewee, $colors-interview-teaser);
			font-size: 0.8em;
			padding: 0.1em 0.5em;
			font-weight: bold;
			line-height: 1.2;
			vertical-align: middle;
		}
	}
}

