.figure {

}

.figure {
  $figure: &;

  &.-has-caption {

  }

  @include breakpoint($bp-medium) {
    &__caption {
      position: absolute;
    }

    &.-caption-bottom-left {
      #{$figure} {
        &__caption {
          bottom: 10px;
          left: 10px;
        }
      }
    }

    &.-caption-bottom-right {
      #{$figure} {
        &__caption {
          bottom: 10px;
          right: 10px;
        }
      }
    }

    &.-caption-upper-left {
      #{$figure} {
        &__caption {
          top: 10px;
          left: 10px;
        }
      }
    }

    &.-caption-upper-right {
      #{$figure} {
        &__caption {
          top: 10px;
          right: 10px;
        }
      }
    }
  }
}

.figure {
  &__image {

  }

  &__caption {
    font-size: 1.3rem;
    line-height: 1.3em;
    color: #7C7B7B;
    margin-top: 1em;
  }
}