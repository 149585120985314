:root {
	--module-spacing-default: 10rem;
	--module-spacing-sm: calc(var(--module-spacing-default) / 2);
	--module-spacing-md: var(--module-spacing-default);
	--module-spacing-lg: calc(var(--module-spacing-default) * 1.5);
	--module-spacing-neg-sm: calc(-1 * var(--module-spacing-sm));
	--module-spacing-neg-md: calc(-1 * var(--module-spacing-md));
	--module-spacing-neg-lg: calc(-1 * var(--module-spacing-lg));

	--spacing-before-default: var(--module-spacing-default);
	--spacing-after-default: var(--module-spacing-default);

	--spacing-before-none: 0;
	--spacing-after-none: 0;

	--spacing-before-sm: var(--module-spacing-sm);
	--spacing-after-sm: var(--module-spacing-sm);

	--spacing-before-md: var(--module-spacing-md);
	--spacing-after-md: var(--module-spacing-md);

	--spacing-before-lg: var(--module-spacing-lg);
	--spacing-after-lg: var(--module-spacing-lg);

	--spacing-before-neg-sm: var(--module-spacing-neg-sm);
	--spacing-after-neg-sm: var(--module-spacing-neg-sm);

	--spacing-before-neg-md: var(--module-spacing-neg-md);
	--spacing-after-neg-md: var(--module-spacing-neg-md);

	--spacing-before-neg-lg: var(--module-spacing-neg-lg);
	--spacing-after-neg-lg: var(--module-spacing-neg-lg);
}

.content {
	margin-top: 3rem;
}
