.module_text-grid {
	&:first-child .rich-text {
		h1 {
			margin-top: 2em;
			margin-bottom: 1em;
			font-size: 2.1rem;

			font-weight: bold;
			color: inherit;
			text-transform: uppercase;
		}
	}
}
