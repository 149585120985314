html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

$root-font-size: 10px;

// Reset font-size to 10px (based on common default browser settings of 16px font-size)
html {
	font-size: ($root-font-size / 16px) * 100%;
}

.website-field {
	display: none
}

button,
input,
optgroup,
select,
textarea {
	font: inherit;
}

p {
	margin-top: 0;

	&:last-child {
		margin-bottom: 0;
	}
}

img {
	display: block;
	height: auto;
	max-width: 100%;
}

h1, h2, h3 {
	font-size: 1em;
}


audio, video {
	max-width: 100%;
}

svg {
	display: inline-block;
	width: 100%;
	height: 100%;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}

button {
	cursor: pointer;
}
