$horizontal-site-padding: rem(20px);

body {
  min-width: 30rem;
}

@mixin content-wrapper() {
  @include container();
  box-sizing: content-box;
  padding: 0 $horizontal-site-padding;

  * {
    box-sizing: border-box;
  }
}

@mixin reset-content-wrapper() {
  box-sizing: border-box;
  padding: 0;
  max-width: none;
}

.content-wrapper{
  @include content-wrapper();
}

.full-width-mobile {
  @include breakpoint($bp-medium) {
    @include content-wrapper();
  }
}

.clearfix {
  @include clearfix();
}

#main-content {
  margin: 2.5rem 0 15rem 0;
}

img[draggable=false] {
  user-select: none;
  -webkit-user-drag: none;
}

.layout-50-50 {
  @include clearfix();

  @include breakpoint($bp-medium) {
    > * {
      @include span(6 of 12);

      &:nth-child(2n+1) {
        @include first();
        clear: both;
      }
      &:nth-child(2n+2) {
        @include last();
      }
    }
  }
}

.layout-33-33-33 {
  @include clearfix();

  @include breakpoint($bp-medium-only) {
    > * {
      @include span(6 of 12);

      &:nth-child(2n+1) {
        @include first();
        clear: both;
      }
      &:nth-child(2n+2) {
        @include last();
      }
    }
  }

  @include breakpoint($bp-large) {
    > * {
      @include span(4 of 12);

      &:nth-child(3n+1) {
        @include first();
        clear: both;
      }
      &:nth-child(3n+3) {
        @include last();
      }
    }
  }
}

.layout-66-33 {
  @include clearfix();

  @include breakpoint($bp-medium) {
    > * {
      &:nth-child(2n+1) {
        @include span(first 6 of 12);
        clear: both;
      }
      &:nth-child(2n+2) {
        @include span(last 6 of 12);
      }
    }
  }

  @include breakpoint($bp-large) {
    > * {
      &:nth-child(2n+1) {
        @include span(first 8 of 12);
        clear: both;
      }
      &:nth-child(2n+2) {
        @include span(last 4 of 12);
      }
    }
  }
}

.layout-75-25 {
  @include pie-clearfix();

  @include breakpoint($bp-medium) {
    > * {
      &:nth-child(2n+1) {
        @include span(first 6 of 12);
        clear: both;
      }
      &:nth-child(2n+2) {
        @include span(last 6 of 12);
      }
    }
  }

  @include breakpoint($bp-large) {
    > * {
      &:nth-child(2n+1) {
        @include span(first 9 of 12);
        clear: both;
      }
      &:nth-child(2n+2) {
        @include span(last 3 of 12);
      }
    }
  }
}

@include breakpoint($bp-mobile-only) {
  .column + .column {
    margin-top: 2rem;
  }
}
