.tag_quote {
  @include clearfix;

  position: relative;
  margin: 3rem 0 5rem 0;

  font-size: 1.8rem;

  @include breakpoint($bp-medium) {
    @include span(10 of 12);
    @include push(1);
    @include post(1);
    float: none;
  }

  @include breakpoint($bp-large) {
    @include span(8 of 12);
    @include post(3);
    float: none;
  }
}

.tag_quote {
  &__content {
    @extend %wf-headings;
    font-size: 1.8rem;

		quotes: "“" "”";

		&:before {
			content: open-quote;
		}

		&:after {
			content: close-quote;
		}
  }


  &__cite {
    @extend %wf-copy;
    font-size: 0.8em;
    font-style: normal;
    line-height: 1.2;

    &-author-name {
      font-weight: bold;
    }

    &-author-position {

    }
  }
}

.tag_quote {
  $root: &;

  &--fullwidth {
    margin-right: 0;
    width: auto;
    margin-left: 0;
  }

  &--big {
    font-size: 2.5rem;
    color: $categoryColor;
    padding-left: 6.5rem;

    #{$root} {
      &__content {
        font-weight: bold;
        line-height: 1.3em;
      }
    }
  }

  &--wifi {
    &:before {
      //@include pseudo-element-defaults(4rem, 4rem);
      @extend %icon-rss;

      font-size: 60px;
      line-height: 1.05em;

      position: absolute;
      top: 50%;
      left: 0;

      transform: rotate(-45deg);
      margin-top: -0.6em;
      margin-left: -0.5em;
    }
  }
}
