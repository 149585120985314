$colors-icon-link: (
	text: color(text, $colors-neutral),
	text-hover: color(vaillant, $colors-brand),
	border: color(vaillant, $colors-brand),
	border-hover: color(vaillant, $colors-brand),
	icon: color(vaillant, $colors-brand),
	icon-hover: color(vaillant, $colors-brand),
);

.tag_icon-link {
	a:not(.button) {
		&:hover, &:active, &:focus {
			text-decoration: none;

			.icon-container {
				&__icon {
					color: color(icon-hover, $colors-icon-link);
					border-color: color(border-hover, $colors-icon-link);

					transform: translate(5px, 0);
				}

				&__label {
					color: color(text-hover, $colors-icon-link);
				}
			}
		}
	}

	.icon-container {
		&__icon {
			color: color(icon, $colors-icon-link);

			height: 4rem;
			width: 4rem;
			padding: 0.5em;
			border: 1px solid color(border, $colors-icon-link);
			border-radius: 50%;
			transition: transform transition(default);
		}

		&.has-text {
			.icon-container__label {
				color: color(text, $colors-icon-link);
				text-transform: uppercase;

				margin-left: 1em;
			}
		}
	}
}

.tag_icon-link {
	&.-align-icon {
		&-left {
			text-align: left;
		}

		&-right {
			text-align: right;
		}

		&-center {
			text-align: center;
		}
	}
}
