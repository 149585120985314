.logo {
	display: flex;
	justify-content: flex-end;
	user-select: none;

	&__link {
		display: inline-block;
	}

	&__image {
		width: 100%;
		height: 100%;
	}
}
