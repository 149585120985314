$colors-text-video: (
	bg: rgba(#323232, 1),
	text: #FFF
);

.text-video {
	background-color: color(bg, $colors-text-video);
	color: color(text, $colors-text-video);

	&__content {
		@include content-wrapper();
		margin: 0 auto;

		display: flex;
		flex-direction: column;
		padding: 2rem;
	}

	&__text {
		@extend %wf-display;

		color: inherit;
		font-size: 4rem;
		line-height: 1.2;
	}
}

// Order modifier
.text-video {
	$root: &;

	&.-text-video {
		#{$root} {
			&__text {
				margin-bottom: 0.5em;
				margin-top: 0.5em;
			}
		}
	}

	&.-video-text {
		#{$root} {
			&__content {
				flex-direction: column-reverse;
			}

			&__text {
				margin-bottom: 0.5em;
				margin-top: 0.5em;
			}
		}
	}
}

@include breakpoint(bp(text-video)) {
	.text-video {
		&__content {
			flex-direction: row;
		}

		&__text {
			display: flex;
			align-items: center;

			margin-bottom: 0;
		}
	}

	// Order modifier
	.text-video {
		$root: &;

		&.-text-video {
			#{$root} {
				&__text {
					width: span(6);
					margin-right: 2 * gutter() + span(1);
				}

				&__video {
					width: span(5);
				}
			}
		}

		&.-video-text {
			#{$root} {
				&__content {
					flex-direction: row-reverse;
				}

				&__video {
					width: span(6);
					margin-right: 2 * gutter() + span(1);
				}

				&__text {
					width: span(5);
				}
			}
		}
	}
}
