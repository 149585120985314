$colors-primary: (
  blue: #005382,
  grey: #878787,
  beige: #E5D1A6,
  dark-blue: #0E5A7F
);

$colors-neutral: (
  grey: #DCDCDC,
  light-grey: #ECECEC,
  dark-grey: #BCBCBC,
  darker-grey: #7C7B7B,
  darkest-grey: #2C2C2C,
  text: #1D1D1B,
  beige: #F3EDE3,
	new-grey: #F1F1F1
);

$colors-secondary: (
  green: #00897E,
  light-green: #36A097,
  blue: #618FB7,
  light-blue: #95B2D0,
  lime: hsl(69.5, 63.9%, 46.7%),
  dark-lime: hsl(69.5, 63.9%, 40%),
  light-lime: #BED65D,
  orange: #F59E3F,
  light-orange: #FABD7A,
  brown: #A78152,
  light-brown: #D8C69D
);

$colors-brand: (
  vaillant: #349380,
  saunier-duval: #E51D1B
);

$category-colors: (
  cover-story: map-get($colors-primary, dark-blue),
  group: map-get($colors-secondary, green),
  brands: map-get($colors-primary, blue),
  employees: map-get($colors-secondary, brown),
);

$colors: map-merge($colors-primary, $colors-neutral);
