.article-teaser {
  border-top: 1px solid map_get($colors-neutral, darker-grey);
  padding-top: 2.5rem;
  margin-bottom: 4rem;

}

.article-teaser {
  &__content {
    padding: 0 2rem 1.5rem 2rem;

    &-subheading {
      position: relative;
      font-size: 2.5rem;
      line-height: 1.2em;
      color: map_get($colors-neutral, text);

      &:before {
        @include pseudo-element-defaults(16px, 16px);
        position: absolute;
        top: 0.6em;
        margin-top: -8px;
        left: -2rem;

        background: sprite($icons-xsmall, 'arrow-right-blue');
        background-repeat: no-repeat;
      }
    }

    &-teaser-text {
      margin-top: 1.5rem;
    }

    &-read-story {

    }
  }

  &__image {
    position: relative;

    img {
      width: 100%;
    }

    &-link {
      display: block;
    }

    &-background {

    }

    &-heading {
      position: absolute;
      bottom: 2rem;

      span {
        position: relative;
        @include background-heading($categoryColor, #FFF, 1em);
        font-size: 1.8rem;

        @include breakpoint($bp-small) {
        }

        @include breakpoint($bp-large) {
          font-size: 2rem;
        }
      }
    }
  }
}