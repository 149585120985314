/* ----------------------------------------------------------------------------
 * DRYed out clearfix
 * @see http://alistapart.com/article/dry-ing-out-your-sass-mixins
 * --------------------------------------------------------------------------*/

/**
 * DRYed out clearfix mixin. Only for internal use here.
 */
@mixin pie-clearfix($extend: true) {
    @if $extend {
        @extend %clearfix;
    } @else {
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
}

/**
 * Use this mixin.
 */
@mixin clearfix($extend: true) {
    @include pie-clearfix($extend);
}

/**
 * Only for internal use here.
 */
%clearfix {
    @include clearfix($extend: false);
}