$colors-category: (
	article-heading: color(lime, $colors-secondary)
);

.category {
	$root: &;

	&__content {
		.module__content {
			padding: 0;

			.content-wrapper {
				padding: 0;
			}
		}

		#{$root} {
			&__article-heading {
				@include content-wrapper();

				color: color(article-heading, $colors-category);
			}
		}
	}
}
