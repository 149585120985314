//$min-screen-width: em(320px);
//$max-content-width: rem(1280px);
//$max-header-width: rem(1470px);
//
//$susy: (
//    columns: 12,
//    gutters: 0.5,
//    container: $max-content-width,
//    container-position: center,
//    math: fluid,
//    output: float,
//    gutter-position: after,
//    global-box-sizing: border-box,
//    use-custom: (
//        background-image: false,
//        background-options: false,
//        box-sizing: true,
//        clearfix: true,
//        rem: true,
//        breakpoint: true,
//    )
//);
//
//@mixin content-wrapper() {
//    width: 100%;
//    padding: 0 3%;
//    margin: 0 auto;
//    max-width: $max-content-width;
//}
//
//.content-wrapper {
//    @include content-wrapper();
//}

$max-content-width: 96rem;

$susy: (
  output: float,
  container: $max-content-width,
  container-position: center,
  columns: 12,
  gutters: 1/4,
  gutter-position: after,
  global-box-sizing: border-box,
  debug: (
    image: hide,
    color: rgba(#66f, .25),
    output: background,
    toggle: top right,
  ),
  use-custom: (
    background-image: false,
    background-options: false,
    box-sizing: true,
    clearfix: false,
    rem: true,
  )
);

$grid-kiosk : (
  gutters: 1/8
);

$grid-issue-toc: (
  gutters: 1/8
);
