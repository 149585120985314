$colors-article: (
	heading: #FFF,
	bg-heading: color(vaillant, $colors-brand)
);

.article__anchor-navigation {
	position: fixed;

	width: rem(240px);
	top: 15rem;
}

.article__hero-heading[class] {
	position: relative;
	font-size: inherit;

	@include breakpoint($bp-mobile-only) {
		padding: 0.6em;
		color: #FFF;
		font-size: 2.3rem;
		line-height: 1.3em;
	}

	@include breakpoint($bp-medium) {
		@include background-heading(color(vaillant, $colors-brand), #FFF);

		bottom: auto;
		left: auto;
	}
}

.article__hero {
	.content-wrapper {
		background-color: color(bg-heading, $colors-article);
	}
}

.article__hero-heading-wrapper {
	font-size: 4rem;

	@include breakpoint($bp-medium) {
		margin-left: 1rem;
	}
}
