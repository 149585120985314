@charset "UTF-8";
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

html {
  font-size: 62.5%; }

.website-field {
  display: none; }

button,
input,
optgroup,
select,
textarea {
  font: inherit; }

p {
  margin-top: 0; }
  p:last-child {
    margin-bottom: 0; }

img {
  display: block;
  height: auto;
  max-width: 100%; }

h1, h2, h3 {
  font-size: 1em; }

audio,
video {
  max-width: 100%; }

svg {
  display: inline-block;
  width: 100%;
  height: 100%;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

button {
  cursor: pointer; }

/**
 * Get variant class name, e.g.
 * $class-name: button
 * $modifier: primary
 * --> result: button--primary
 */
/**
 * Returns the breakpoint value.
 */
/* ----------------------------------------------------------------------------
 * Pseude elements, background images
 * --------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------------
 * DRYed out clearfix
 * @see http://alistapart.com/article/dry-ing-out-your-sass-mixins
 * --------------------------------------------------------------------------*/
/**
 * DRYed out clearfix mixin. Only for internal use here.
 */
/**
 * Use this mixin.
 */
/**
 * Only for internal use here.
 */
.clearfix:after, .layout-50-50:after, .layout-33-33-33:after, .layout-66-33:after, .layout-75-25:after, .article-teaser--grid:after, .article-teaser-collection__entries:after, .box-collection__entries:after, .module_column-layout:after, .tag_quote:after {
  content: '';
  display: table;
  clear: both; }

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);

  or object-fit and object-position:
  @include object-fit(cover, top);

  @see https://github.com/bfred-it/object-fit-images/
*/
/* ----------------------------------------------------------------------------
 * Positioning
 * --------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------------
 * Compass fallback
 * --------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------------
 * Vaillant utils
 * --------------------------------------------------------------------------*/
.image-container.left {
  float: left;
  margin-right: gutter(); }

.image-container.right {
  float: right;
  margin-left: gutter(); }

.image-container img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto; }

/* ----------------------------------------------------------------------------
 * Vaillant Type
 * --------------------------------------------------------------------------*/
@font-face {
  font-family: 'CompatilFact';
  src: url("../fonts/compatilfactltpro-rg.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'CompatilFact';
  src: url("../fonts/compatilfactltpro-bd.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

h1, h2, h3, h4, h5, h6, .content h1, .content h2, .tag_quote__content, .article__main-content h2, .interview-teaser__interviewee .interviewee, .button.default, .button.more.default, .button.more.default.white, .button.more.secondary, .button.more.secondary.white, .button.less.default, .button.less.default.white, .button.less.secondary, .button.less.secondary.white, .button.next.default, .button.next.default.white, .button.next.secondary, .button.next.secondary.white, .button.previous.default, .button.previous.default.white, .button.previous.secondary, .button.previous.secondary.white, .button.issue span, .article-teaser-collection--slider .article-teaser-collection__entries .slider .pagination .pages .page, .media-collection__medium-image .figure__caption, .dialog__close-button, .text-video__text, .tag_cta, .page-strategy .tag_cta {
  font-family: "CompatilFact", "PT Sans", Roboto, "Segeo UI", Arial, Helvetica, sans-serif; }

body, .tag_quote__cite {
  font-family: Arial, Helvetica, sans-serif; }

/* ----------------------------------------------------------------------------
 * Icons
 * --------------------------------------------------------------------------*/
@font-face {
  font-family: 'vaillant-life-icons';
  src: url("../fonts/vaillant-life-icons.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.tag_quote--wifi:before {
  font-family: "vaillant-life-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.tag_quote--wifi:before {
  content: '\e800'; }

::selection {
  background: #95B2D0;
  text-shadow: none; }

body {
  color: #1D1D1B;
  font-size: 1.6rem; }

p {
  line-height: 1.5em;
  margin-top: 0;
  margin-bottom: 1em; }
  p:last-child {
    margin-bottom: 0; }
  p small {
    line-height: 1; }

a {
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
  cursor: pointer; }
  a:active, a:focus {
    outline: none; }

dl {
  font-size: 1.4rem;
  line-height: 1.5em; }
  dl dt {
    font-weight: bold; }
    dl dt:after {
      content: ": "; }
  dl dd {
    margin: 0;
    margin-bottom: 0.5em;
    padding: 0; }

.compact dl dt:before {
  content: "";
  display: block; }

.compact dl dt, .compact dl dd {
  display: inline; }

.todo {
  color: #E51D1B;
  font-weight: bold;
  margin: 2em 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal; }

.upper {
  text-transform: uppercase; }

.rich-text.-two-columns {
  columns: 2;
  column-width: 40ch;
  column-gap: 2em; }

.content {
  font-size: 1.6rem; }
  .content h1, .content h2 {
    color: #005382;
    font-size: 4rem;
    margin-bottom: 0.5em; }
  .content h3 {
    color: #005382;
    font-size: 2rem;
    margin-top: 2em;
    margin-bottom: 0.5em; }
  .content h4 {
    font-size: 2rem;
    margin-top: 2em;
    margin-bottom: 0.5em; }
  .content h5 {
    font-size: 1.8rem;
    color: #005382;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold; }
  .content h6 {
    font-size: 1.6rem;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold; }
  .content a:not(.button) {
    color: #005382; }
    .content a:not(.button):hover, .content a:not(.button):focus, .content a:not(.button):active {
      text-decoration: underline; }
  .content ul:not([class]) li, .content ol:not([class]) li {
    margin-bottom: 0.7em;
    line-height: 1.5em; }
  .content table:not([class]) {
    margin: 2rem 0; }
    .content table:not([class]) td, .content table:not([class]) th {
      padding: 0.5em; }

.input-radio {
  display: block;
  position: relative;
  cursor: pointer; }
  .input-radio__input {
    position: absolute;
    opacity: 0;
    height: 100%; }
    .input-radio__input + .input-radio__indicator:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      border: 0.2rem solid #FFF;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transform: translateX(-50%) translateY(-50%);
      transition: width 0.15s linear, height 0.15s linear;
      width: 0;
      height: 0; }
    .input-radio__input:checked + .input-radio__indicator:before {
      width: 1.2rem;
      height: 1.2rem; }
  .input-radio__indicator {
    position: absolute;
    width: 1.4rem;
    height: 1.4rem;
    top: 0.7em;
    margin-top: -0.7rem;
    background-color: #FFF;
    border: 1px solid #BCBCBC;
    border-radius: 50%; }
  .input-radio__text {
    font-size: 1.5rem;
    height: 100%;
    user-select: none;
    display: block;
    padding-left: 1.96rem;
    line-height: 1.5; }

.life .input-radio__indicator:before {
  background-color: #349380; }

body {
  min-width: 30rem; }

.content-wrapper {
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  padding: 0 2rem; }
  .content-wrapper:after {
    content: " ";
    display: block;
    clear: both; }
  .content-wrapper * {
    box-sizing: border-box; }

@media (min-width: 40em) {
  .full-width-mobile {
    max-width: 96rem;
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
    padding: 0 2rem; }
    .full-width-mobile:after {
      content: " ";
      display: block;
      clear: both; }
    .full-width-mobile * {
      box-sizing: border-box; } }

#main-content {
  margin: 2.5rem 0 15rem 0; }

img[draggable=false] {
  user-select: none;
  -webkit-user-drag: none; }

@media (min-width: 40em) {
  .layout-50-50 > * {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .layout-50-50 > *:nth-child(2n+1) {
      margin-left: 0;
      clear: both; }
    .layout-50-50 > *:nth-child(2n+2) {
      float: right;
      margin-right: 0; } }

@media (min-width: 40em) and (max-width: 49.9375em) {
  .layout-33-33-33 > * {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .layout-33-33-33 > *:nth-child(2n+1) {
      margin-left: 0;
      clear: both; }
    .layout-33-33-33 > *:nth-child(2n+2) {
      float: right;
      margin-right: 0; } }

@media (min-width: 50em) {
  .layout-33-33-33 > * {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
    .layout-33-33-33 > *:nth-child(3n+1) {
      margin-left: 0;
      clear: both; }
    .layout-33-33-33 > *:nth-child(3n+3) {
      float: right;
      margin-right: 0; } }

@media (min-width: 40em) {
  .layout-66-33 > *:nth-child(2n+1) {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .layout-66-33 > *:nth-child(2n+2) {
    width: 49.15254%;
    float: right;
    margin-right: 0; } }

@media (min-width: 50em) {
  .layout-66-33 > *:nth-child(2n+1) {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .layout-66-33 > *:nth-child(2n+2) {
    width: 32.20339%;
    float: right;
    margin-right: 0; } }

@media (min-width: 40em) {
  .layout-75-25 > *:nth-child(2n+1) {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .layout-75-25 > *:nth-child(2n+2) {
    width: 49.15254%;
    float: right;
    margin-right: 0; } }

@media (min-width: 50em) {
  .layout-75-25 > *:nth-child(2n+1) {
    width: 74.57627%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .layout-75-25 > *:nth-child(2n+2) {
    width: 23.72881%;
    float: right;
    margin-right: 0; } }

@media (min-width: 0.0625em) and (max-width: 39.9375em) {
  .column + .column {
    margin-top: 2rem; } }

.collapsed {
  display: none !important; }

.animated {
  overflow: hidden !important; }

.slider {
  width: 100%; }
  .slider.pagination-above {
    padding-top: 75px; }
  .slider.pagination-below {
    padding-bottom: 50px; }
  .slider.directional-nav-above {
    padding-top: 75px; }
  .slider.directional-nav-below {
    padding-bottom: 50px; }
  .slider .viewport {
    overflow: hidden; }
    .slider .viewport .slides-wrapper {
      white-space: nowrap;
      list-style: none;
      margin: 0;
      padding: 0; }
      .slider .viewport .slides-wrapper .slide {
        display: inline-block;
        white-space: normal;
        vertical-align: top; }
  .slider .directional-nav.hidden, .slider .pagination.hidden {
    display: none !important; }
  .slider .pagination {
    width: 100%;
    height: 50px;
    position: absolute; }
    .slider .pagination.above {
      top: 0; }
    .slider .pagination .dots {
      width: 100%;
      line-height: 50px;
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: center; }
      .slider .pagination .dots.locked {
        opacity: 0.3; }
      .slider .pagination .dots .dot {
        z-index: 10;
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 1px solid #A0A0A0;
        border-radius: 50%;
        margin: 0 5px 0 0;
        vertical-align: middle; }
        .slider .pagination .dots .dot.controllable {
          cursor: pointer; }
        .slider .pagination .dots .dot.active {
          width: 10px;
          height: 10px;
          border-color: transparent;
          background-color: #C0C0C0; }
          .slider .pagination .dots .dot.active.primary {
            background-color: #A0A0A0; }
        .slider .pagination .dots .dot:last-child {
          margin-right: 0; }
    .slider .pagination .bar {
      width: 100%;
      height: 10px;
      border: 1px solid #A0A0A0;
      top: 20px; }
      .slider .pagination .bar .progress {
        height: 8px;
        background-color: #A0A0A0;
        position: absolute; }
    .slider .pagination .pages {
      list-style: none;
      height: 100%;
      margin: 0;
      padding: 0;
      margin-top: 25px;
      border-top: 1px solid grey;
      text-align: center; }
      .slider .pagination .pages.locked {
        opacity: 0.3; }
      .slider .pagination .pages .page {
        display: inline-block;
        height: 100%;
        width: 1.8em;
        text-align: center;
        vertical-align: baseline;
        line-height: 40px;
        font-size: 1.8rem;
        color: grey;
        margin-top: -2px;
        margin-right: 1rem;
        height: 100%;
        border-top: 3px solid transparent; }
        .slider .pagination .pages .page.controllable {
          cursor: pointer; }
        .slider .pagination .pages .page:last-child {
          margin-right: 0; }
        .slider .pagination .pages .page.selected {
          color: grey;
          border-top: 3px solid grey; }
  .slider .directional-nav {
    width: 100%;
    position: absolute; }
    .slider .directional-nav.locked {
      opacity: 0.3; }
    .slider .directional-nav.above {
      top: 0; }
    .slider .directional-nav.top {
      top: 0; }
    .slider .directional-nav.middle {
      top: 50%;
      margin-top: -25px; }
    .slider .directional-nav.bottom {
      bottom: 100px; }
    .slider .directional-nav .next, .slider .directional-nav .previous {
      z-index: 10;
      height: 50px;
      width: 50px;
      cursor: pointer;
      position: absolute;
      user-select: none; }

.swipe-trigger {
  cursor: move; }

.read-more {
  display: inline-block;
  position: relative;
  color: #005382;
  text-decoration: none;
  padding-left: 16px; }
  .read-more:hover, .read-more:focus, .read-more:active {
    text-decoration: none; }
  .read-more:before {
    content: ' ';
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("../images/sprites/icons-xsmall/arrow-light-right-blue.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -8px;
    left: 0; }

.button.default {
  background-color: #005382;
  color: #FFF;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  text-align: left;
  font-size: 1.6rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem; }
  .button.default:hover, .button.default:focus, .button.default:active {
    background-color: #0E5A7F;
    text-decoration: none; }

.button.more.default {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  background-color: #005382;
  color: #FFF;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px; }
  .button.more.default:hover, .button.more.default:focus, .button.more.default:active {
    text-decoration: none; }
  .button.more.default:before, .button.more.default:after {
    background: url("../images/sprites/icons-small/plus-white.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.more.default:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.more.default:before, .button.more.default:after {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .button.more.default:hover, .button.more.default:focus, .button.more.default:active {
    background-color: #0E5A7F; }
    .button.more.default:hover:before, .button.more.default:hover:after, .button.more.default:focus:before, .button.more.default:focus:after, .button.more.default:active:before, .button.more.default:active:after {
      transform: translate(-5px); }

.button.more.default.white {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  background-color: #FFF;
  color: #FFF;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px;
  color: #0E5A7F; }
  .button.more.default.white:hover, .button.more.default.white:focus, .button.more.default.white:active {
    text-decoration: none; }
  .button.more.default.white:before, .button.more.default.white:after {
    background: url("../images/sprites/icons-small/plus-blue.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.more.default.white:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.more.default.white:before, .button.more.default.white:after {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .button.more.default.white:hover, .button.more.default.white:focus, .button.more.default.white:active {
    background-color: #ECECEC; }
    .button.more.default.white:hover:before, .button.more.default.white:hover:after, .button.more.default.white:focus:before, .button.more.default.white:focus:after, .button.more.default.white:active:before, .button.more.default.white:active:after {
      transform: translate(-5px); }

.button.more.secondary {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  color: #005382;
  padding-right: 39px; }
  .button.more.secondary:hover, .button.more.secondary:focus, .button.more.secondary:active {
    text-decoration: none; }
  .button.more.secondary:before, .button.more.secondary:after {
    background: url("../images/sprites/icons-small/plus-blue.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.more.secondary:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.more.secondary span {
    display: inline-block;
    padding-top: 14px;
    padding-bottom: 12px;
    border-bottom: 2px solid #005382; }
  .button.more.secondary:hover, .button.more.secondary:focus, .button.more.secondary:active {
    color: #0E5A7F; }
    .button.more.secondary:hover span, .button.more.secondary:focus span, .button.more.secondary:active span {
      border-bottom-color: #0E5A7F; }
  .button.more.secondary:after {
    right: 0; }

.button.more.secondary.white {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  color: #005382;
  padding-right: 39px;
  color: #FFF; }
  .button.more.secondary.white:hover, .button.more.secondary.white:focus, .button.more.secondary.white:active {
    text-decoration: none; }
  .button.more.secondary.white:before, .button.more.secondary.white:after {
    background: url("../images/sprites/icons-small/plus-white.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.more.secondary.white:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.more.secondary.white span {
    display: inline-block;
    padding-top: 14px;
    padding-bottom: 12px;
    border-bottom: 2px solid #005382; }
  .button.more.secondary.white:hover, .button.more.secondary.white:focus, .button.more.secondary.white:active {
    color: #0E5A7F; }
    .button.more.secondary.white:hover span, .button.more.secondary.white:focus span, .button.more.secondary.white:active span {
      border-bottom-color: #0E5A7F; }
  .button.more.secondary.white:after {
    right: 0; }
  .button.more.secondary.white span {
    border-bottom-color: #FFF; }
  .button.more.secondary.white:hover, .button.more.secondary.white:focus, .button.more.secondary.white:active {
    color: #ECECEC; }
    .button.more.secondary.white:hover span, .button.more.secondary.white:focus span, .button.more.secondary.white:active span {
      border-bottom-color: #ECECEC; }

.button.more span.less {
  display: none; }

.button.less.default {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  background-color: #005382;
  color: #FFF;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px; }
  .button.less.default:hover, .button.less.default:focus, .button.less.default:active {
    text-decoration: none; }
  .button.less.default:before, .button.less.default:after {
    background: url("../images/sprites/icons-small/minus-white.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.less.default:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.less.default:before, .button.less.default:after {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .button.less.default:hover, .button.less.default:focus, .button.less.default:active {
    background-color: #0E5A7F; }
    .button.less.default:hover:before, .button.less.default:hover:after, .button.less.default:focus:before, .button.less.default:focus:after, .button.less.default:active:before, .button.less.default:active:after {
      transform: translate(-5px); }

.button.less.default.white {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  background-color: #FFF;
  color: #FFF;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px;
  color: #0E5A7F; }
  .button.less.default.white:hover, .button.less.default.white:focus, .button.less.default.white:active {
    text-decoration: none; }
  .button.less.default.white:before, .button.less.default.white:after {
    background: url("../images/sprites/icons-small/minus-blue.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.less.default.white:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.less.default.white:before, .button.less.default.white:after {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .button.less.default.white:hover, .button.less.default.white:focus, .button.less.default.white:active {
    background-color: #ECECEC; }
    .button.less.default.white:hover:before, .button.less.default.white:hover:after, .button.less.default.white:focus:before, .button.less.default.white:focus:after, .button.less.default.white:active:before, .button.less.default.white:active:after {
      transform: translate(-5px); }

.button.less.secondary {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  color: #005382;
  padding-right: 39px; }
  .button.less.secondary:hover, .button.less.secondary:focus, .button.less.secondary:active {
    text-decoration: none; }
  .button.less.secondary:before, .button.less.secondary:after {
    background: url("../images/sprites/icons-small/minus-blue.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.less.secondary:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.less.secondary span {
    display: inline-block;
    padding-top: 14px;
    padding-bottom: 12px;
    border-bottom: 2px solid #005382; }
  .button.less.secondary:hover, .button.less.secondary:focus, .button.less.secondary:active {
    color: #0E5A7F; }
    .button.less.secondary:hover span, .button.less.secondary:focus span, .button.less.secondary:active span {
      border-bottom-color: #0E5A7F; }
  .button.less.secondary:after {
    right: 0; }

.button.less.secondary.white {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  color: #005382;
  padding-right: 39px;
  color: #FFF; }
  .button.less.secondary.white:hover, .button.less.secondary.white:focus, .button.less.secondary.white:active {
    text-decoration: none; }
  .button.less.secondary.white:before, .button.less.secondary.white:after {
    background: url("../images/sprites/icons-small/minus-white.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.less.secondary.white:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.less.secondary.white span {
    display: inline-block;
    padding-top: 14px;
    padding-bottom: 12px;
    border-bottom: 2px solid #005382; }
  .button.less.secondary.white:hover, .button.less.secondary.white:focus, .button.less.secondary.white:active {
    color: #0E5A7F; }
    .button.less.secondary.white:hover span, .button.less.secondary.white:focus span, .button.less.secondary.white:active span {
      border-bottom-color: #0E5A7F; }
  .button.less.secondary.white:after {
    right: 0; }
  .button.less.secondary.white span {
    border-bottom-color: #FFF; }
  .button.less.secondary.white:hover, .button.less.secondary.white:focus, .button.less.secondary.white:active {
    color: #ECECEC; }
    .button.less.secondary.white:hover span, .button.less.secondary.white:focus span, .button.less.secondary.white:active span {
      border-bottom-color: #ECECEC; }

.button.less span.more {
  display: none; }

.button.next.default {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  background-color: #005382;
  color: #FFF;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px; }
  .button.next.default:hover, .button.next.default:focus, .button.next.default:active {
    text-decoration: none; }
  .button.next.default:before, .button.next.default:after {
    background: url("../images/sprites/icons-small/arrow-light-right-white.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.next.default:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.next.default:before, .button.next.default:after {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .button.next.default:hover, .button.next.default:focus, .button.next.default:active {
    background-color: #0E5A7F; }
    .button.next.default:hover:before, .button.next.default:hover:after, .button.next.default:focus:before, .button.next.default:focus:after, .button.next.default:active:before, .button.next.default:active:after {
      transform: translate(-5px); }

.button.next.default.white {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  background-color: #FFF;
  color: #FFF;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px;
  color: #0E5A7F; }
  .button.next.default.white:hover, .button.next.default.white:focus, .button.next.default.white:active {
    text-decoration: none; }
  .button.next.default.white:before, .button.next.default.white:after {
    background: url("../images/sprites/icons-small/arrow-light-right-blue.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.next.default.white:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.next.default.white:before, .button.next.default.white:after {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .button.next.default.white:hover, .button.next.default.white:focus, .button.next.default.white:active {
    background-color: #ECECEC; }
    .button.next.default.white:hover:before, .button.next.default.white:hover:after, .button.next.default.white:focus:before, .button.next.default.white:focus:after, .button.next.default.white:active:before, .button.next.default.white:active:after {
      transform: translate(-5px); }

.button.next.secondary {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  color: #005382;
  padding-right: 39px; }
  .button.next.secondary:hover, .button.next.secondary:focus, .button.next.secondary:active {
    text-decoration: none; }
  .button.next.secondary:before, .button.next.secondary:after {
    background: url("../images/sprites/icons-small/arrow-light-right-blue.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.next.secondary:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.next.secondary span {
    display: inline-block;
    padding-top: 14px;
    padding-bottom: 12px;
    border-bottom: 2px solid #005382; }
  .button.next.secondary:hover, .button.next.secondary:focus, .button.next.secondary:active {
    color: #0E5A7F; }
    .button.next.secondary:hover span, .button.next.secondary:focus span, .button.next.secondary:active span {
      border-bottom-color: #0E5A7F; }
  .button.next.secondary:after {
    right: 0; }

.button.next.secondary.white {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  color: #005382;
  padding-right: 39px;
  color: #FFF; }
  .button.next.secondary.white:hover, .button.next.secondary.white:focus, .button.next.secondary.white:active {
    text-decoration: none; }
  .button.next.secondary.white:before, .button.next.secondary.white:after {
    background: url("../images/sprites/icons-small/arrow-light-right-white.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.next.secondary.white:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .button.next.secondary.white span {
    display: inline-block;
    padding-top: 14px;
    padding-bottom: 12px;
    border-bottom: 2px solid #005382; }
  .button.next.secondary.white:hover, .button.next.secondary.white:focus, .button.next.secondary.white:active {
    color: #0E5A7F; }
    .button.next.secondary.white:hover span, .button.next.secondary.white:focus span, .button.next.secondary.white:active span {
      border-bottom-color: #0E5A7F; }
  .button.next.secondary.white:after {
    right: 0; }
  .button.next.secondary.white span {
    border-bottom-color: #FFF; }
  .button.next.secondary.white:hover, .button.next.secondary.white:focus, .button.next.secondary.white:active {
    color: #ECECEC; }
    .button.next.secondary.white:hover span, .button.next.secondary.white:focus span, .button.next.secondary.white:active span {
      border-bottom-color: #ECECEC; }

.button.previous.default {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 52px;
  background-color: #005382;
  color: #FFF;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px; }
  .button.previous.default:hover, .button.previous.default:focus, .button.previous.default:active {
    text-decoration: none; }
  .button.previous.default:before, .button.previous.default:after {
    background: url("../images/sprites/icons-small/arrow-light-left-white.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.previous.default:before {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    left: 13px; }
  .button.previous.default:before, .button.previous.default:after {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .button.previous.default:hover, .button.previous.default:focus, .button.previous.default:active {
    background-color: #0E5A7F; }
    .button.previous.default:hover:before, .button.previous.default:hover:after, .button.previous.default:focus:before, .button.previous.default:focus:after, .button.previous.default:active:before, .button.previous.default:active:after {
      transform: translate(5px); }

.button.previous.default.white {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 52px;
  background-color: #FFF;
  color: #FFF;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
  color: #0E5A7F; }
  .button.previous.default.white:hover, .button.previous.default.white:focus, .button.previous.default.white:active {
    text-decoration: none; }
  .button.previous.default.white:before, .button.previous.default.white:after {
    background: url("../images/sprites/icons-small/arrow-light-left-blue.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.previous.default.white:before {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    left: 13px; }
  .button.previous.default.white:before, .button.previous.default.white:after {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .button.previous.default.white:hover, .button.previous.default.white:focus, .button.previous.default.white:active {
    background-color: #ECECEC; }
    .button.previous.default.white:hover:before, .button.previous.default.white:hover:after, .button.previous.default.white:focus:before, .button.previous.default.white:focus:after, .button.previous.default.white:active:before, .button.previous.default.white:active:after {
      transform: translate(5px); }

.button.previous.secondary {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 52px;
  color: #005382;
  padding-left: 39px; }
  .button.previous.secondary:hover, .button.previous.secondary:focus, .button.previous.secondary:active {
    text-decoration: none; }
  .button.previous.secondary:before, .button.previous.secondary:after {
    background: url("../images/sprites/icons-small/arrow-light-left-blue.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.previous.secondary:before {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    left: 13px; }
  .button.previous.secondary span {
    display: inline-block;
    padding-top: 14px;
    padding-bottom: 12px;
    border-bottom: 2px solid #005382; }
  .button.previous.secondary:hover, .button.previous.secondary:focus, .button.previous.secondary:active {
    color: #0E5A7F; }
    .button.previous.secondary:hover span, .button.previous.secondary:focus span, .button.previous.secondary:active span {
      border-bottom-color: #0E5A7F; }
  .button.previous.secondary:before {
    left: 0; }

.button.previous.secondary.white {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 52px;
  color: #005382;
  padding-left: 39px;
  color: #FFF; }
  .button.previous.secondary.white:hover, .button.previous.secondary.white:focus, .button.previous.secondary.white:active {
    text-decoration: none; }
  .button.previous.secondary.white:before, .button.previous.secondary.white:after {
    background: url("../images/sprites/icons-small/arrow-light-left-white.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .button.previous.secondary.white:before {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    left: 13px; }
  .button.previous.secondary.white span {
    display: inline-block;
    padding-top: 14px;
    padding-bottom: 12px;
    border-bottom: 2px solid #005382; }
  .button.previous.secondary.white:hover, .button.previous.secondary.white:focus, .button.previous.secondary.white:active {
    color: #0E5A7F; }
    .button.previous.secondary.white:hover span, .button.previous.secondary.white:focus span, .button.previous.secondary.white:active span {
      border-bottom-color: #0E5A7F; }
  .button.previous.secondary.white:before {
    left: 0; }
  .button.previous.secondary.white span {
    border-bottom-color: #FFF; }
  .button.previous.secondary.white:hover, .button.previous.secondary.white:focus, .button.previous.secondary.white:active {
    color: #ECECEC; }
    .button.previous.secondary.white:hover span, .button.previous.secondary.white:focus span, .button.previous.secondary.white:active span {
      border-bottom-color: #ECECEC; }

.button.center {
  text-align: center; }

.button.right {
  text-align: center; }

.button.center a, .button.right a {
  text-align: left; }

.button.issue {
  text-transform: uppercase; }
  .button.issue:before {
    content: ' ';
    display: inline-block;
    width: 50px;
    height: 50px;
    display: block;
    background-repeat: no-repeat;
    margin: 0 auto; }
  .button.issue span {
    display: block;
    font-size: 2rem;
    margin-top: 0.5em; }
  .button.issue.download:before {
    background: url("../images/sprites/icons-large/download.png") no-repeat center center; }
  .button.issue.read:before {
    background: url("../images/sprites/icons-large/read.png") no-repeat center center; }
  @media (min-width: 40em) {
    .button.issue {
      text-align: left; }
      .button.issue:before {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1em; }
      .button.issue span {
        display: inline-block;
        vertical-align: middle;
        margin-top: 0; } }

figure figcaption {
  font-size: 1.3rem;
  line-height: 1.3em;
  color: #7C7B7B;
  margin-top: 1em; }

.icon-container {
  display: inline-block;
  font-size: 1.6rem;
  color: #BCBCBC; }

.icon-container__link {
  color: #005382;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  .icon-container__link:hover {
    color: #0E5A7F; }

.icon-container__icon {
  width: 1em;
  height: 1em; }

.icon-container.has-text .icon-container__link {
  display: flex;
  align-items: center; }

.icon-container.has-text .icon-container__label {
  margin-left: 0.75rem; }

.article-teaser {
  border-top: 1px solid #7C7B7B;
  padding-top: 2.5rem;
  margin-bottom: 4rem; }

.article-teaser__content {
  padding: 0 2rem 1.5rem 2rem; }
  .article-teaser__content-subheading {
    position: relative;
    font-size: 2.5rem;
    line-height: 1.2em;
    color: #1D1D1B; }
    .article-teaser__content-subheading:before {
      content: ' ';
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 0.6em;
      margin-top: -8px;
      left: -2rem;
      background: url("../images/sprites/icons-xsmall/arrow-right-blue.png") no-repeat center center;
      background-repeat: no-repeat; }
  .article-teaser__content-teaser-text {
    margin-top: 1.5rem; }

.article-teaser__image {
  position: relative; }
  .article-teaser__image img {
    width: 100%; }
  .article-teaser__image-link {
    display: block; }
  .article-teaser__image-heading {
    position: absolute;
    bottom: 2rem; }
    .article-teaser__image-heading span {
      position: relative;
      color: #FFF;
      display: inline;
      line-height: 2.1;
      left: 1.65em;
      padding: 0.4em 0 0.4em 0;
      background-color: #005382;
      box-shadow: 0.6em 0 0 #005382, -0.65em 0 0 #005382;
      box-decoration-break: clone;
      text-transform: uppercase;
      font-size: 1.8rem; }
      @media (min-width: 50em) {
        .article-teaser__image-heading span {
          font-size: 2rem; } }

.article-teaser--full, .article-teaser--leading {
  border: none; }
  .article-teaser--full .article-teaser__image, .article-teaser--leading .article-teaser__image {
    position: relative; }
    .article-teaser--full .article-teaser__image img, .article-teaser--leading .article-teaser__image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover";
      max-width: none; }
    .article-teaser--full .article-teaser__image-background, .article-teaser--leading .article-teaser__image-background, .article-teaser--full .article-teaser__image-link, .article-teaser--leading .article-teaser__image-link {
      position: relative;
      width: 100%;
      height: 100%; }
    @media (min-width: 40em) {
      .article-teaser--full .article-teaser__image-heading span, .article-teaser--leading .article-teaser__image-heading span {
        font-size: 2.2rem; } }
    @media (min-width: 50em) {
      .article-teaser--full .article-teaser__image-heading span, .article-teaser--leading .article-teaser__image-heading span {
        font-size: 2.8rem; } }
  .article-teaser--full .article-teaser__content, .article-teaser--leading .article-teaser__content {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.65) 100%), url("../images/layout/stripes_light-blue.png");
    background-position: center top;
    background-repeat: no-repeat, repeat;
    padding: 2rem;
    padding-bottom: 3rem; }
    .article-teaser--full .article-teaser__content-teaser-text, .article-teaser--leading .article-teaser__content-teaser-text {
      margin-bottom: 2rem; }
  .article-teaser--full .article-teaser__controls, .article-teaser--leading .article-teaser__controls {
    margin-bottom: 0;
    margin-top: auto; }
  @media (min-width: 40em) {
    .article-teaser--full, .article-teaser--leading {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      min-height: 350px; }
      .article-teaser--full .article-teaser__image, .article-teaser--leading .article-teaser__image {
        width: 49.15254%; }
      .article-teaser--full .article-teaser__content, .article-teaser--leading .article-teaser__content {
        width: 49.15254%; } }
  @media (min-width: 50em) {
    .article-teaser--full .article-teaser__image, .article-teaser--leading .article-teaser__image {
      width: 66.10169%; }
    .article-teaser--full .article-teaser__content, .article-teaser--leading .article-teaser__content {
      width: 32.20339%; } }

.article-teaser-collection__entry:nth-child(2n+2) .article-teaser--full .article-teaser__image, .article-teaser-collection__entry:nth-child(2n+2) .article-teaser--leading .article-teaser__image {
  order: 2; }

.article-teaser-collection__entry:nth-child(2n+2) .article-teaser--full .article-teaser__content, .article-teaser-collection__entry:nth-child(2n+2) .article-teaser--leading .article-teaser__content {
  order: 1; }

.article-teaser--grid .article-teaser__image {
  float: left;
  width: 33%;
  margin-right: 1.69492%; }
  .article-teaser--grid .article-teaser__image a, .article-teaser--grid .article-teaser__image img {
    display: block; }
  .article-teaser--grid .article-teaser__image img {
    max-width: 100%; }

.article-teaser--grid .article-teaser__content {
  float: right;
  width: 64.30508%;
  font-size: 1.4rem;
  padding: 0 1rem; }
  .article-teaser--grid .article-teaser__content-heading {
    position: relative;
    font-size: 1.7rem;
    font-weight: bold;
    color: #1D1D1B; }
    .article-teaser--grid .article-teaser__content-heading:before {
      left: -1.5rem;
      background: url("../images/sprites/icons-xsmall/arrow-small-right-grey.png") no-repeat center center; }

.article-teaser--leading {
  background-color: #FFF;
  border: none;
  padding: 0; }
  .article-teaser--leading .article-teaser__image img {
    user-select: none; }
  .article-teaser--leading .article-teaser__content {
    background-image: none; }

.article-teaser-collection__entry:nth-child(2n+2) .article-teaser--leading .article-teaser__image {
  order: 1; }

.article-teaser-collection__entry:nth-child(2n+2) .article-teaser--leading .article-teaser__content {
  order: 2; }

.article-teaser--slider .article-teaser__image-heading {
  bottom: 1rem;
  left: 2.4rem;
  right: 1.4rem; }
  .article-teaser--slider .article-teaser__image-heading span {
    left: 0; }

@media (min-width: 40em) {
  .figure__caption {
    position: absolute; }
  .figure.-caption-bottom-left .figure__caption {
    bottom: 10px;
    left: 10px; }
  .figure.-caption-bottom-right .figure__caption {
    bottom: 10px;
    right: 10px; }
  .figure.-caption-upper-left .figure__caption {
    top: 10px;
    left: 10px; }
  .figure.-caption-upper-right .figure__caption {
    top: 10px;
    right: 10px; } }

.figure__caption {
  font-size: 1.3rem;
  line-height: 1.3em;
  color: #7C7B7B;
  margin-top: 1em; }

.details-image__details {
  background-color: #FFF; }
  .details-image__details-inner {
    padding: 2rem;
    padding-bottom: 3rem; }
  .details-image__details-content {
    padding: 0; }
  .details-image__details.has-cta .details-image__details-inner {
    padding-bottom: 115px; }
  .details-image__details.has-cta .button {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
    text-align: center; }
  .details-image__details.striped .details-image__details-inner {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.65) 100%), url("../images/layout/stripes_light-blue.png");
    background-position: center top;
    background-repeat: no-repeat, repeat; }

@media (min-width: 40em) {
  .details-image {
    min-height: 350px; } }

.article-teaser-collection {
  margin-bottom: 2rem;
  margin-bottom: 5rem; }
  .article-teaser-collection__heading {
    font-size: 4rem;
    margin-bottom: 1rem;
    color: #005382; }
  .article-teaser-collection__entries {
    list-style: none;
    margin: 0;
    padding: 0; }

.article-teaser-collection--leading {
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  padding: 0 2rem;
  padding: 0;
  margin-bottom: 0; }
  .article-teaser-collection--leading:after {
    content: " ";
    display: block;
    clear: both; }
  .article-teaser-collection--leading * {
    box-sizing: border-box; }
  .article-teaser-collection--leading .article-teaser {
    margin: 0; }
  @media (min-width: 30em) and (max-width: 39.9375em) {
    .article-teaser-collection--leading .article-teaser-collection__entries {
      background-color: #FFF; } }
  .article-teaser-collection--leading .article-teaser-collection__entries {
    position: relative; }
    .article-teaser-collection--leading .article-teaser-collection__entries .slider {
      padding-bottom: 0; }
      .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav, .article-teaser-collection--leading .article-teaser-collection__entries .slider .pagination {
        padding-top: 10px; }
      @media (min-width: 73.75em) {
        .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav, .article-teaser-collection--leading .article-teaser-collection__entries .slider .pagination {
          padding-top: 0; } }
      .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav .next {
        opacity: 1;
        background: url("../images/sprites/icons-large/slider-arrow-right-blue.png") no-repeat center center; }
        .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav .next:hover, .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav .next:focus, .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav .next:active {
          background: url("../images/sprites/icons-large/slider-arrow-right-blue_hover.png") no-repeat center center; }
        @media (min-width: 73.75em) {
          .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav .next {
            right: -70px !important; } }
      .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav .previous {
        opacity: 1;
        background: url("../images/sprites/icons-large/slider-arrow-left-blue.png") no-repeat center center; }
        .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav .previous:hover, .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav .previous:focus, .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav .previous:active {
          background: url("../images/sprites/icons-large/slider-arrow-left-blue_hover.png") no-repeat center center; }
        @media (min-width: 73.75em) {
          .article-teaser-collection--leading .article-teaser-collection__entries .slider .directional-nav .previous {
            left: -70px !important; } }
    .article-teaser-collection--leading .article-teaser-collection__entries .slider {
      padding-bottom: 0; }

.article-teaser-collection--expandable .article-teaser-collection__entry {
  padding-top: 1rem;
  margin-bottom: 2rem; }
  @media (min-width: 30em) and (max-width: 39.9375em) {
    .article-teaser-collection--expandable .article-teaser-collection__entry {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; }
      .article-teaser-collection--expandable .article-teaser-collection__entry:nth-child(2n+2) {
        float: right;
        margin-right: 0; }
      .article-teaser-collection--expandable .article-teaser-collection__entry:nth-child(2n+1) {
        clear: both; } }
  @media (min-width: 40em) {
    .article-teaser-collection--expandable .article-teaser-collection__entry {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; }
      .article-teaser-collection--expandable .article-teaser-collection__entry:nth-child(2n+2) {
        float: right;
        margin-right: 0; }
      .article-teaser-collection--expandable .article-teaser-collection__entry:nth-child(2n+1) {
        clear: both; } }

.article-teaser-collection--slider .article-teaser-collection__entries {
  position: relative;
  user-select: none; }
  .article-teaser-collection--slider .article-teaser-collection__entries .slider {
    padding-bottom: 0; }
    .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav, .article-teaser-collection--slider .article-teaser-collection__entries .slider .pagination {
      padding-top: 10px; }
    @media (min-width: 73.75em) {
      .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav, .article-teaser-collection--slider .article-teaser-collection__entries .slider .pagination {
        padding-top: 0; } }
    .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .next {
      opacity: 1;
      background: url("../images/sprites/icons-large/slider-arrow-right-blue.png") no-repeat center center; }
      .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .next:hover, .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .next:focus, .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .next:active {
        background: url("../images/sprites/icons-large/slider-arrow-right-blue_hover.png") no-repeat center center; }
      @media (min-width: 73.75em) {
        .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .next {
          right: -70px !important; } }
    .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .previous {
      opacity: 1;
      background: url("../images/sprites/icons-large/slider-arrow-left-blue.png") no-repeat center center; }
      .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .previous:hover, .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .previous:focus, .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .previous:active {
        background: url("../images/sprites/icons-large/slider-arrow-left-blue_hover.png") no-repeat center center; }
      @media (min-width: 73.75em) {
        .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .previous {
          left: -70px !important; } }
  .article-teaser-collection--slider .article-teaser-collection__entries .slider .pagination .pages {
    border-top: 1px solid #BCBCBC; }
    .article-teaser-collection--slider .article-teaser-collection__entries .slider .pagination .pages .page {
      color: #BCBCBC; }
      .article-teaser-collection--slider .article-teaser-collection__entries .slider .pagination .pages .page.selected {
        color: #2C2C2C;
        border-top: 3px solid #2C2C2C; }
  .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .previous {
    background: url("../images/sprites/icons-large/arrow-round-left.png") no-repeat center center; }
    @media (min-width: 73.75em) {
      .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .previous {
        left: 50px !important; } }
    .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .previous:hover, .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .previous:focus, .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .previous:active {
      background: url("../images/sprites/icons-large/slider-arrow-left-blue_hover.png") no-repeat center center; }
  .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .next {
    background: url("../images/sprites/icons-large/arrow-round-right.png") no-repeat center center; }
    @media (min-width: 73.75em) {
      .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .next {
        right: 50px !important; } }
    .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .next:hover, .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .next:focus, .article-teaser-collection--slider .article-teaser-collection__entries .slider .directional-nav .next:active {
      background: url("../images/sprites/icons-large/slider-arrow-right-blue_hover.png") no-repeat center center; }

.article-teaser-collection--grid .article-teaser-collection__entry {
  padding-top: 1rem;
  margin-bottom: 2rem; }
  @media (min-width: 30em) and (max-width: 39.9375em) {
    .article-teaser-collection--grid .article-teaser-collection__entry {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; }
      .article-teaser-collection--grid .article-teaser-collection__entry:nth-child(2n+2) {
        float: right;
        margin-right: 0; }
      .article-teaser-collection--grid .article-teaser-collection__entry:nth-child(2n+1) {
        clear: both; } }
  @media (min-width: 40em) and (max-width: 49.9375em) {
    .article-teaser-collection--grid .article-teaser-collection__entry {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; }
      .article-teaser-collection--grid .article-teaser-collection__entry:nth-child(2n+2) {
        float: right;
        margin-right: 0; }
      .article-teaser-collection--grid .article-teaser-collection__entry:nth-child(2n+1) {
        clear: both; } }
  @media (min-width: 50em) {
    .article-teaser-collection--grid .article-teaser-collection__entry {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; }
      .article-teaser-collection--grid .article-teaser-collection__entry:nth-child(3n+3) {
        float: right;
        margin-right: 0; }
      .article-teaser-collection--grid .article-teaser-collection__entry:nth-child(3n+1) {
        clear: both; } }

.box-collection {
  margin-bottom: 4rem; }
  @media (min-width: 0.0625em) and (max-width: 39.9375em) {
    .box-collection__entry {
      width: 100%; } }
  @media (min-width: 40em) {
    .box-collection--layout-50-50 .box-collection__entry {
      width: 49.15254%;
      margin-right: 1.69492%; }
      .box-collection--layout-50-50 .box-collection__entry .box-collection__details {
        width: 203.44828%; }
      .box-collection--layout-50-50 .box-collection__entry:nth-child(2n+1) .box-collection__teaser {
        background-position: left bottom; }
      .box-collection--layout-50-50 .box-collection__entry:nth-child(2n+1) .box-collection__details {
        background-position: left top;
        left: 0; }
      .box-collection--layout-50-50 .box-collection__entry:nth-child(2n+2) {
        margin-right: 0; }
        .box-collection--layout-50-50 .box-collection__entry:nth-child(2n+2) .box-collection__teaser {
          background-position: right bottom; }
        .box-collection--layout-50-50 .box-collection__entry:nth-child(2n+2) .box-collection__details {
          background-position: right top;
          right: 0; } }
  @media (min-width: 40em) and (max-width: 49.9375em) {
    .box-collection--layout-33-33-33 .box-collection__entry {
      width: 49.15254%;
      margin-right: 1.69492%; }
      .box-collection--layout-33-33-33 .box-collection__entry .box-collection__details {
        width: 203.44828%; }
      .box-collection--layout-33-33-33 .box-collection__entry:nth-child(2n+1) .box-collection__teaser {
        background-position: left bottom; }
      .box-collection--layout-33-33-33 .box-collection__entry:nth-child(2n+1) .box-collection__details {
        background-position: left top;
        left: 0; }
      .box-collection--layout-33-33-33 .box-collection__entry:nth-child(2n+2) {
        margin-right: 0; }
        .box-collection--layout-33-33-33 .box-collection__entry:nth-child(2n+2) .box-collection__teaser {
          background-position: right bottom; }
        .box-collection--layout-33-33-33 .box-collection__entry:nth-child(2n+2) .box-collection__details {
          background-position: right top;
          right: 0; } }
  @media (min-width: 50em) {
    .box-collection--layout-33-33-33 .box-collection__entry {
      width: 32.20339%;
      margin-right: 1.69492%; }
      .box-collection--layout-33-33-33 .box-collection__entry .box-collection__details {
        width: 310.52632%; }
      .box-collection--layout-33-33-33 .box-collection__entry:nth-child(3n+1) .box-collection__teaser {
        background-position: left bottom; }
      .box-collection--layout-33-33-33 .box-collection__entry:nth-child(3n+1) .box-collection__details {
        background-position: left top;
        left: 0; }
      .box-collection--layout-33-33-33 .box-collection__entry:nth-child(3n+2) .box-collection__teaser {
        background-position: center bottom; }
      .box-collection--layout-33-33-33 .box-collection__entry:nth-child(3n+2) .box-collection__details {
        background-position: center top;
        left: -105.26316%; }
      .box-collection--layout-33-33-33 .box-collection__entry:nth-child(3n+3) {
        margin-right: 0; }
        .box-collection--layout-33-33-33 .box-collection__entry:nth-child(3n+3) .box-collection__teaser {
          background-position: right bottom; }
        .box-collection--layout-33-33-33 .box-collection__entry:nth-child(3n+3) .box-collection__details {
          background-position: right top;
          right: 0; } }

.box-collection {
  position: relative; }
  .box-collection__entries {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0; }
    .box-collection__entries .box-collection__entry {
      margin-bottom: 0;
      margin-top: 2rem; }
  .box-collection__entry {
    position: relative;
    margin: 0;
    padding: 0; }
  .box-collection__teaser {
    position: relative;
    height: 100%;
    z-index: 3;
    border: 1px solid #ECECEC; }
    .box-collection__teaser-image {
      min-height: 1px; }
    .box-collection__teaser-content {
      padding: 3rem 1.5rem 2rem; }
      .box-collection__teaser-content > *:first-child {
        margin-top: 0; }
      .box-collection__teaser-content > *:last-child {
        margin-bottom: 0; }
    .box-collection__teaser-button {
      text-align: center;
      margin-top: auto;
      margin-bottom: 4rem; }
  .box-collection__details {
    position: absolute;
    top: 100%;
    margin-top: -1px;
    z-index: 2;
    overflow: hidden;
    background-color: #FFF;
    background-image: url("../images/layout/stripes_01.png");
    background-repeat: repeat; }
    .box-collection__details.collapsed {
      height: 0; }
    .box-collection__details-content {
      border: 1px solid #ECECEC;
      padding: 1.5rem;
      padding-bottom: 4rem; }

.box-collection.is-expandable .box-collection__teaser {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .box-collection.is-expandable .box-collection__teaser-content {
    margin-top: 0;
    margin-bottom: 3rem;
    padding-bottom: 0; }

.box-collection.is-expandable .box-collection__entry.open {
  z-index: 10; }
  .box-collection.is-expandable .box-collection__entry.open .box-collection__teaser {
    border-bottom: none;
    background-color: #FFF;
    background-image: url("../images/layout/stripes_01.png");
    background-repeat: repeat; }

.interview {
  margin: 3rem 0; }

.interview__entry {
  font-size: 1.4rem;
  line-height: 1.5em; }
  .interview__entry--question {
    margin-bottom: 0 !important;
    font-weight: normal; }
    .interview__entry--question:after {
      content: ""; }
  .interview__entry--answer {
    margin-bottom: 0 !important;
    margin: 0;
    padding: 0; }
  .interview__entry-name {
    text-transform: uppercase;
    font-weight: bold;
    user-select: none; }

@media (min-width: 0.0625em) and (max-width: 39.9375em) {
  .interview__entry--question, .interview__entry--answer {
    margin-bottom: 0;
    padding-top: 1.5em;
    padding-bottom: 2em; }
  .interview__entry--question, .interview__entry--answer, .interview__entry-name {
    padding-left: 2rem;
    padding-right: 2rem; }
  .interview__entry-name {
    display: block;
    margin-bottom: -1px;
    border: 1px solid #BCBCBC;
    border-left-width: 0;
    border-right-width: 0;
    padding-top: 1em;
    padding-bottom: 1em;
    cursor: pointer;
    margin-bottom: 0;
    margin-bottom: 1.5em;
    background-color: #ECECEC; }
    .interview__entry-name:after {
      content: " ";
      width: 50px;
      height: 50px;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -25px;
      background-repeat: no-repeat; } }

@media (min-width: 40em) {
  .interview__entry--question, .interview__entry--answer {
    margin-bottom: 0.5em !important; }
  .interview__entry--question {
    margin-top: 3em; }
    .interview__entry--question:first-child {
      margin-top: 0; }
  .interview__entry-name {
    display: inline;
    text-transform: none; }
    .interview__entry-name:after {
      content: ":  "; } }

.media-collection {
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  padding: 0 2rem;
  padding: 0; }
  .media-collection:after {
    content: " ";
    display: block;
    clear: both; }
  .media-collection * {
    box-sizing: border-box; }

.media-collection__entries {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative; }
  .media-collection__entries .slider {
    padding-bottom: 0; }
    .media-collection__entries .slider .directional-nav, .media-collection__entries .slider .pagination {
      padding-top: 10px; }
    @media (min-width: 73.75em) {
      .media-collection__entries .slider .directional-nav, .media-collection__entries .slider .pagination {
        padding-top: 0; } }
    .media-collection__entries .slider .directional-nav .next {
      opacity: 1;
      background: url("../images/sprites/icons-large/slider-arrow-right-blue.png") no-repeat center center; }
      .media-collection__entries .slider .directional-nav .next:hover, .media-collection__entries .slider .directional-nav .next:focus, .media-collection__entries .slider .directional-nav .next:active {
        background: url("../images/sprites/icons-large/slider-arrow-right-blue_hover.png") no-repeat center center; }
      @media (min-width: 73.75em) {
        .media-collection__entries .slider .directional-nav .next {
          right: -70px !important; } }
    .media-collection__entries .slider .directional-nav .previous {
      opacity: 1;
      background: url("../images/sprites/icons-large/slider-arrow-left-blue.png") no-repeat center center; }
      .media-collection__entries .slider .directional-nav .previous:hover, .media-collection__entries .slider .directional-nav .previous:focus, .media-collection__entries .slider .directional-nav .previous:active {
        background: url("../images/sprites/icons-large/slider-arrow-left-blue_hover.png") no-repeat center center; }
      @media (min-width: 73.75em) {
        .media-collection__entries .slider .directional-nav .previous {
          left: -70px !important; } }

.media-collection__medium-image .figure {
  position: relative;
  margin: 0; }
  .media-collection__medium-image .figure__image {
    width: 100%;
    max-width: 100%;
    height: auto; }
  .media-collection__medium-image .figure__caption {
    font-size: 1.5rem;
    line-height: 1.3em;
    color: #7C7B7B;
    padding: 1.5rem;
    background-color: #FFF; }
    @media (min-width: 40em) {
      .media-collection__medium-image .figure__caption {
        max-width: 50%; } }
    @media (min-width: 50em) {
      .media-collection__medium-image .figure__caption {
        max-width: 33%; } }

.media-collection__medium-image-details {
  background: #FFF;
  padding: 1px; }

.media-collection__medium--details-image .figure {
  display: block;
  margin: 0; }
  .media-collection__medium--details-image .figure img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    font-family: "object-fit: cover";
    max-width: none; }

.media-collection__medium--details-image .media-collection__medium-image-details {
  background-color: #FFF;
  padding: 2rem;
  padding-bottom: 3rem; }
  .media-collection__medium--details-image .media-collection__medium-image-details .tag_quote {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }

@media (min-width: 40em) {
  .media-collection__medium--details-image {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-height: 350px; }
    .media-collection__medium--details-image .figure {
      width: 49.15254%; }
    .media-collection__medium--details-image .media-collection__medium-image-details {
      width: 49.15254%; } }

@media (min-width: 50em) {
  .media-collection__medium--details-image {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-height: 350px; }
    .media-collection__medium--details-image .figure {
      width: 66.10169%; }
    .media-collection__medium--details-image .media-collection__medium-image-details {
      width: 32.20339%; } }

.media-collection__medium-video .video-container {
  width: 100%; }

.module {
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  padding: 0 2rem;
  scroll-margin-top: 8rem; }
  .module:after {
    content: " ";
    display: block;
    clear: both; }
  .module * {
    box-sizing: border-box; }

.module.-overwrite-child-margin > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.module.margin-top-small {
  margin-top: 0.5rem; }

.module.margin-top-medium {
  margin-top: 1rem; }

.module.margin-top-large {
  margin-top: 2.5rem; }

.module.margin-bottom-small {
  margin-bottom: 0.5rem; }

.module.margin-bottom-medium {
  margin-bottom: 1rem; }

.module.margin-bottom-large {
  margin-bottom: 3.75rem; }

@media (min-width: 42.5em) {
  .module.margin-top-small {
    margin-top: 1rem; }
  .module.margin-top-medium {
    margin-top: 2rem; }
  .module.margin-top-large {
    margin-top: 5rem; }
  .module.margin-bottom-small {
    margin-bottom: 1.5rem; }
  .module.margin-bottom-medium {
    margin-bottom: 3rem; }
  .module.margin-bottom-large {
    margin-bottom: 7.5rem; } }

@media (min-width: 64em) {
  .module.margin-top-small {
    margin-top: 2rem; }
  .module.margin-top-medium {
    margin-top: 4rem; }
  .module.margin-top-large {
    margin-top: 10rem; }
  .module.margin-bottom-small {
    margin-bottom: 2rem; }
  .module.margin-bottom-medium {
    margin-bottom: 4rem; }
  .module.margin-bottom-large {
    margin-bottom: 10rem; } }

@media (min-width: 90em) {
  .module.margin-top-small {
    margin-top: 3rem; }
  .module.margin-top-medium {
    margin-top: 6rem; }
  .module.margin-top-large {
    margin-top: 15rem; }
  .module.margin-bottom-small {
    margin-bottom: 3rem; }
  .module.margin-bottom-medium {
    margin-bottom: 6rem; }
  .module.margin-bottom-large {
    margin-bottom: 15rem; } }

.module_column {
  box-sizing: border-box;
  padding: 0;
  max-width: none; }

.module_column-layout {
  clear: both;
  float: none;
  margin-top: 3rem;
  margin-bottom: 3rem; }
  .module_column-layout .module {
    box-sizing: border-box;
    padding: 0;
    max-width: none; }
  .module_column-layout > div.span-3 {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
  .module_column-layout > div.span-4 {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
  .module_column-layout > div.span-5 {
    width: 40.67797%;
    float: left;
    margin-right: 1.69492%; }
  .module_column-layout > div.span-6 {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .module_column-layout .module_column.first, .module_column-layout .module_column.left {
    margin-left: 0; }
  .module_column-layout .module_column.last, .module_column-layout .module_column.right {
    float: right;
    margin-right: 0; }

@media (min-width: 40em) {
  .module_column-layout--50-50 .module_column {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .module_column-layout--50-50 .module_column:nth-child(2n+1) {
      margin-left: 0;
      clear: both; }
    .module_column-layout--50-50 .module_column:nth-child(2n+2) {
      float: right;
      margin-right: 0; } }

@media (min-width: 40em) {
  .module_column-layout--75-25 .module_column:nth-child(2n+1) {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .module_column-layout--75-25 .module_column:nth-child(2n+2) {
    width: 32.20339%;
    float: right;
    margin-right: 0; } }

@media (min-width: 40em) {
  .module_column-layout--25-75 .module_column:nth-child(2n+1) {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .module_column-layout--25-75 .module_column:nth-child(2n+2) {
    width: 66.10169%;
    float: right;
    margin-right: 0; } }

@media (min-width: 40em) {
  .module_column-layout--66-33 .module_column:nth-child(2n+1) {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .module_column-layout--66-33 .module_column:nth-child(2n+2) {
    width: 32.20339%;
    float: right;
    margin-right: 0; } }

@media (min-width: 40em) {
  .module_column-layout--33-66 .module_column:nth-child(2n+1) {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .module_column-layout--33-66 .module_column:nth-child(2n+2) {
    width: 66.10169%;
    float: right;
    margin-right: 0; } }

@media (min-width: 40em) and (max-width: 49.9375em) {
  .module_column-layout--50-25-25 .module_column:nth-child(3n+1) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    clear: both; }
  .module_column-layout--50-25-25 .module_column:nth-child(3n+2) {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .module_column-layout--50-25-25 .module_column:nth-child(3n+3) {
    width: 49.15254%;
    float: right;
    margin-right: 0; } }

@media (min-width: 50em) {
  .module_column-layout--50-25-25 .module_column:nth-child(3n+1) {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .module_column-layout--50-25-25 .module_column:nth-child(3n+2) {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
  .module_column-layout--50-25-25 .module_column:nth-child(3n+3) {
    width: 23.72881%;
    float: right;
    margin-right: 0; } }

@media (min-width: 40em) and (max-width: 49.9375em) {
  .module_column-layout--25-50-25 .module_column {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
    .module_column-layout--25-50-25 .module_column:nth-child(3n+1) {
      margin-left: 0;
      clear: both; }
    .module_column-layout--25-50-25 .module_column:nth-child(3n+3) {
      float: right;
      margin-right: 0; } }

@media (min-width: 50em) {
  .module_column-layout--25-50-25 .module_column:nth-child(3n+1) {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .module_column-layout--25-50-25 .module_column:nth-child(3n+2) {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .module_column-layout--25-50-25 .module_column:nth-child(3n+3) {
    width: 23.72881%;
    float: right;
    margin-right: 0; } }

@media (min-width: 40em) and (max-width: 49.9375em) {
  .module_column-layout--25-25-50 .module_column:nth-child(3n+1) {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .module_column-layout--25-25-50 .module_column:nth-child(3n+2) {
    width: 49.15254%;
    float: right;
    margin-right: 0; }
  .module_column-layout--25-25-50 .module_column:nth-child(3n+3) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; } }

@media (min-width: 50em) {
  .module_column-layout--25-25-50 .module_column:nth-child(3n+1) {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%;
    clear: both; }
  .module_column-layout--25-25-50 .module_column:nth-child(3n+2) {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
  .module_column-layout--25-25-50 .module_column:nth-child(3n+3) {
    width: 49.15254%;
    float: right;
    margin-right: 0; } }

@media (min-width: 40em) {
  .module_column-layout--25-25-25-25 .module_column {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
    .module_column-layout--25-25-25-25 .module_column:nth-child(4n+1) {
      margin-left: 0;
      clear: both; }
    .module_column-layout--25-25-25-25 .module_column:nth-child(4n+4) {
      float: right;
      margin-right: 0; } }

@media (min-width: 40em) {
  .module_column-layout--33-33-33 .module_column {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
    .module_column-layout--33-33-33 .module_column:nth-child(3n+1) {
      margin-left: 0;
      clear: both; }
    .module_column-layout--33-33-33 .module_column:nth-child(3n+3) {
      float: right;
      margin-right: 0; } }

.tag_quote {
  position: relative;
  margin: 3rem 0 5rem 0;
  font-size: 1.8rem; }
  @media (min-width: 40em) {
    .tag_quote {
      width: 83.05085%;
      float: left;
      margin-right: 1.69492%;
      margin-left: 8.47458%;
      margin-right: 8.47458%;
      float: none; } }
  @media (min-width: 50em) {
    .tag_quote {
      width: 66.10169%;
      float: left;
      margin-right: 1.69492%;
      margin-right: 25.42373%;
      float: none; } }

.tag_quote__content {
  font-size: 1.8rem;
  quotes: "“" "”"; }
  .tag_quote__content:before {
    content: open-quote; }
  .tag_quote__content:after {
    content: close-quote; }

.tag_quote__cite {
  font-size: 0.8em;
  font-style: normal;
  line-height: 1.2; }
  .tag_quote__cite-author-name {
    font-weight: bold; }

.tag_quote--fullwidth {
  margin-right: 0;
  width: auto;
  margin-left: 0; }

.tag_quote--big {
  font-size: 2.5rem;
  color: #005382;
  padding-left: 6.5rem; }
  .tag_quote--big .tag_quote__content {
    font-weight: bold;
    line-height: 1.3em; }

.tag_quote--wifi:before {
  font-size: 60px;
  line-height: 1.05em;
  position: absolute;
  top: 50%;
  left: 0;
  transform: rotate(-45deg);
  margin-top: -0.6em;
  margin-left: -0.5em; }

.article__hero {
  position: relative;
  margin-bottom: 3rem; }
  .article__hero-image {
    width: 100%;
    overflow: hidden; }
    @media (min-width: 40em) {
      .article__hero-image {
        max-height: 450px; } }
    @media (min-width: 50em) {
      .article__hero-image {
        max-height: 550px; } }
    .article__hero-image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center center;
      object-position: center center;
      font-family: "object-fit: cover; object-position: center center";
      max-width: none; }
  @media (min-width: 0.0625em) and (max-width: 39.9375em) {
    .article__hero .content-wrapper {
      background-color: #005382; } }
  @media (min-width: 40em) {
    .article__hero-heading-wrapper {
      position: absolute;
      bottom: 1em; } }
  .article__hero-heading {
    font-size: 2rem;
    text-transform: uppercase; }
    @media (min-width: 0.0625em) and (max-width: 39.9375em) {
      .article__hero-heading {
        padding: 0.6em;
        color: #FFF;
        font-size: 2.3rem;
        line-height: 1.3em; } }
    @media (min-width: 40em) {
      .article__hero-heading {
        color: #FFF;
        display: inline;
        line-height: 2.1;
        left: 0.65em;
        padding: 0.4em 0 0.4em 0;
        background-color: #005382;
        box-shadow: 0.6em 0 0 #005382, -0.65em 0 0 #005382;
        box-decoration-break: clone;
        text-transform: uppercase;
        position: relative; } }
    @media (min-width: 50em) {
      .article__hero-heading {
        font-size: 3rem;
        bottom: 2rem; } }

.article__main-content {
  font-size: 1.6rem; }
  .article__main-content h1 {
    font-size: 2rem;
    margin-bottom: 0.5em; }
  .article__main-content h2 {
    font-size: 4rem;
    margin-bottom: 0.5em; }
  .article__main-content h3 {
    color: #005382;
    font-size: 2rem;
    margin-top: 2em;
    margin-bottom: 0.5em; }
  .article__main-content h4 {
    font-size: 2rem;
    margin-top: 2em;
    margin-bottom: 0.5em; }
  .article__main-content h5 {
    font-size: 1.8rem;
    color: #005382;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold; }
  .article__main-content h6 {
    font-size: 1.6rem;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold; }
  .article__main-content a:not(.button) {
    color: #005382; }
    .article__main-content a:not(.button):hover, .article__main-content a:not(.button):focus, .article__main-content a:not(.button):active {
      text-decoration: underline; }
  .article__main-content ul:not([class]) li, .article__main-content ol:not([class]) li {
    margin-bottom: 0.7em;
    line-height: 1.5em; }
  .article__main-content table:not([class]) {
    margin: 2rem 0; }
    .article__main-content table:not([class]) td, .article__main-content table:not([class]) th {
      padding: 0.5em; }

.article__comments {
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  padding: 0 2rem; }
  .article__comments:after {
    content: " ";
    display: block;
    clear: both; }
  .article__comments * {
    box-sizing: border-box; }
  .article__comments .article-controls-form {
    margin-bottom: 4rem; }
  .article__comments-title {
    font-size: 2rem; }
  .article__comments-disabled {
    font-size: 1.5rem;
    margin-top: 2rem; }
  .article__comments-form {
    margin: 2rem 0; }

.article__modules + .article__modules {
  position: relative;
  margin-top: var(--module-spacing-md); }
  .article__modules + .article__modules:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #abc32b;
    max-width: 96rem;
    margin: 0 auto var(--module-spacing-sm); }

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: #349380; }

::selection {
  background: #349380;
  color: #FFF; }

@keyframes appear {
  from {
    transform: translate(-50%, -40%);
    opacity: 0; }
  to {
    transform: translate(-50%, -50%);
    opacity: 1; } }

.hero-headline {
  display: grid;
  grid-template-rows: auto auto;
  font-size: 4rem;
  font-size: clamp(4rem, 10vw, 6.8rem);
  font-weight: bold;
  line-height: 0.9;
  text-transform: uppercase; }
  .hero-headline > span {
    display: block; }

@media all and (-ms-high-contrast: none) and (min-width: 48em), (-ms-high-contrast: active) and (min-width: 48em) {
  .hero-headline {
    font-size: 5.4rem; } }

@media all and (-ms-high-contrast: none) and (min-width: 80em), (-ms-high-contrast: active) and (min-width: 80em) {
  .hero-headline {
    font-size: 6.8rem; } }

.hero-headline__word {
  visibility: hidden;
  opacity: 1;
  grid-column: 1;
  grid-row: 2;
  color: inherit;
  animation-name: swap-words;
  animation-iteration-count: infinite;
  animation: swap-words var(--animated-headline-duration) steps(var(--animated-headline-words)) infinite; }
  .hero-headline__word:nth-child(1) {
    animation-delay: calc(var(--animated-headline-interval)  * -1); }
  .hero-headline__word:nth-child(2) {
    animation-delay: calc(var(--animated-headline-interval)  * 0); }
  .hero-headline__word:nth-child(3) {
    animation-delay: calc(var(--animated-headline-interval)  * 1); }
  .hero-headline__word:nth-child(4) {
    animation-delay: calc(var(--animated-headline-interval)  * 2); }
  .hero-headline__word:nth-child(5) {
    animation-delay: calc(var(--animated-headline-interval)  * 3); }
  .hero-headline__word:nth-child(6) {
    animation-delay: calc(var(--animated-headline-interval)  * 4); }
  .hero-headline__word:nth-child(7) {
    animation-delay: calc(var(--animated-headline-interval)  * 5); }
  .hero-headline__word:nth-child(8) {
    animation-delay: calc(var(--animated-headline-interval)  * 6); }
  .hero-headline__word:nth-child(9) {
    animation-delay: calc(var(--animated-headline-interval)  * 7); }
  .hero-headline__word:nth-child(10) {
    animation-delay: calc(var(--animated-headline-interval)  * 8); }

.anchor-navigation {
  z-index: 100;
  width: 24rem;
  background-color: #FFF;
  color: #349380; }

.anchor-navigation,
.anchor-navigation:not(.is-open) .anchor-navigation__controls {
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.2); }

@supports (filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.2))) {
  .anchor-navigation {
    box-shadow: none;
    filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.2)); } }

.anchor-navigation__controls {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  position: absolute;
  right: -4.2rem;
  top: 0;
  width: 4.2rem;
  height: 4.2rem; }

.anchor-navigation__control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #FFF; }
  .anchor-navigation__control:focus {
    border: 1px dotted; }
  .anchor-navigation__control-icon {
    color: #349380; }
    .anchor-navigation__control-icon .icon-container__icon {
      width: 1.4rem;
      height: 1.4rem;
      min-width: 1.4rem;
      min-height: 1.4rem;
      transition: transform 0.25s cubic-bezier(0.23, 1, 0.32, 1); }

.anchor-navigation__content {
  padding: 1.6rem; }

.anchor-navigation__entries {
  list-style: none;
  margin: 0;
  padding: 0; }

.anchor-navigation__entry {
  margin-top: 0.5em; }
  .anchor-navigation__entry:first-child {
    margin-top: 0; }
  .anchor-navigation__entry-link {
    display: block;
    padding: 0.25em; }
    .anchor-navigation__entry-link .icon-container__link {
      color: #349380; }
    .anchor-navigation__entry-link .icon-container__icon {
      width: 1.4rem;
      height: 1.4rem;
      min-width: 1.4rem;
      min-height: 1.4rem; }
    .anchor-navigation__entry-link .icon-container__label {
      font-size: 1.4rem; }

.anchor-navigation {
  transform: translateX(-100%);
  transition: transform 0.25s cubic-bezier(0.23, 1, 0.32, 1); }
  .anchor-navigation.is-open {
    transform: translateX(0); }
    .anchor-navigation.is-open .anchor-navigation__control {
      transform: rotate(180deg); }

.anchor-navigation {
  position: absolute; }
  .anchor-navigation.is-sticky {
    position: fixed; }

.box-collection {
  margin: 0; }
  .box-collection__teaser {
    height: auto; }

.box-collection__teaser {
  display: flex;
  flex-direction: column; }
  .box-collection__teaser .tag_quote {
    margin: 0;
    width: 100%; }

.box-collection__entry-heading.box-collection__entry-heading {
  font-size: 2.4rem; }

.box-collection__teaser-button .button.default, .box-collection__teaser-button .button.default.more, .box-collection__teaser-button .button.default.less {
  background-color: #349380; }
  .box-collection__teaser-button .button.default:hover, .box-collection__teaser-button .button.default:active, .box-collection__teaser-button .button.default:focus, .box-collection__teaser-button .button.default.more:hover, .box-collection__teaser-button .button.default.more:active, .box-collection__teaser-button .button.default.more:focus, .box-collection__teaser-button .button.default.less:hover, .box-collection__teaser-button .button.default.less:active, .box-collection__teaser-button .button.default.less:focus {
    background-color: #349380; }

.compact-navigation-bar__content {
  display: flex;
  flex-direction: column;
  align-items: center; }

.compact-navigation-bar__logo {
  margin-top: 2rem; }
  .compact-navigation-bar__logo .logo__link {
    width: 13rem; }

@media (min-width: 42.5em) {
  .compact-navigation-bar__content {
    flex-direction: row; }
  .compact-navigation-bar__logo {
    margin-top: 0;
    margin-left: auto; } }

.compact-navigation-bar--logo-first .compact-navigation-bar__content {
  flex-direction: column-reverse; }

.compact-navigation-bar--logo-first .compact-navigation-bar__logo {
  margin-top: 0;
  margin-bottom: 2rem; }

@media (min-width: 42.5em) {
  .compact-navigation-bar--logo-first .compact-navigation-bar__content {
    flex-direction: row; }
  .compact-navigation-bar--logo-first .compact-navigation-bar__logo {
    margin-bottom: 0; } }

[data-a11y-dialog-native] > .dialog-container__overlay {
  display: none; }

.dialog-container[aria-hidden='true'] {
  display: none; }

dialog[open] {
  display: block; }

.dialog-container .dialog {
  animation: appear 0.5s cubic-bezier(0.23, 1, 0.32, 1) 150ms both; }

.dialog-container {
  background-color: rgba(0, 0, 0, 0.66);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 201; }

.dialog-container__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 202; }

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 203;
  padding: 2rem;
  background-color: #FFF; }

.dialog__title-bar {
  display: flex;
  margin-bottom: 3rem; }

.dialog__close-button {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #005382;
  color: #FFF;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 0;
  width: 4rem;
  height: 4rem; }
  .dialog__close-button:hover, .dialog__close-button:focus, .dialog__close-button:active {
    text-decoration: none; }
  .dialog__close-button:before, .dialog__close-button:after {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .dialog__close-button:hover, .dialog__close-button:focus, .dialog__close-button:active {
    background-color: #0E5A7F; }
    .dialog__close-button:hover:before, .dialog__close-button:hover:after, .dialog__close-button:focus:before, .dialog__close-button:focus:after, .dialog__close-button:active:before, .dialog__close-button:active:after {
      transform: translate(-5px); }
  .dialog__close-button .icon-container__icon {
    display: block;
    color: #7C7B7B; }
  .dialog__close-button, .dialog__close-button:focus {
    background-color: transparent;
    border: 1px solid #7C7B7B;
    border-radius: 50%; }
  .dialog__close-button:hover, .dialog__close-button:active {
    background-color: transparent;
    border-color: #349380; }
    .dialog__close-button:hover .icon-container__icon, .dialog__close-button:active .icon-container__icon {
      color: #349380; }

.dialog__content {
  max-width: calc(90vw - 2rem);
  max-height: calc(90vh - 2rem - 3rem); }

.dialog__image {
  object-fit: contain;
  width: 100%;
  height: 100%; }

.hotspot-image {
  position: relative; }

.hotspot-image__background-image {
  width: 100%; }

.hotspot-image__hotspot {
  position: absolute; }

.hotspot-image__hotspot-marker {
  display: flex;
  justify-content: center;
  align-items: center; }
  .hotspot-image__hotspot-marker-control {
    width: 32px;
    height: 32px;
    background-color: white;
    border: 2px solid #005382;
    border-radius: 50%;
    outline: none;
    cursor: pointer; }
    .hotspot-image__hotspot-marker-control:focus {
      background-color: #005382; }
      .hotspot-image__hotspot-marker-control:focus .hotspot-image__hotspot-marker-icon {
        color: white; }
  .hotspot-image__hotspot-marker-icon {
    color: #005382; }

.hotspot-image__hotspot-content {
  background-color: white;
  border-bottom: 2px solid #005382;
  border-left: 2px solid #005382;
  color: #005382;
  padding: 1rem;
  position: absolute;
  width: 240px; }
  .hotspot-image__hotspot-content .rich-text {
    max-width: 80ch; }
  .hotspot-image__hotspot-content-control {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent; }
    .hotspot-image__hotspot-content-control:focus {
      border: 2px solid #005382; }
  .hotspot-image__hotspot-content-controls {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem; }
  .hotspot-image__hotspot-content-icon {
    color: #005382;
    cursor: pointer; }

.hotspot-image__hotspot-content {
  display: none; }

.hotspot-image__hotspot.is-open .hotspot-image__hotspot-content {
  display: block; }

.hotspot-image__hotspot.-align-top .hotspot-image__hotspot-content {
  top: 0; }

.hotspot-image__hotspot.-align-bottom .hotspot-image__hotspot-content {
  bottom: 0; }

.hotspot-image__hotspot.-align-right .hotspot-image__hotspot-content {
  right: 0; }

.hotspot-image__hotspot.-align-left .hotspot-image__hotspot-content {
  left: 0; }

.main-navigation {
  user-select: none; }

.main-navigation__entries {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  font-size: clamp(1.4rem, 2vw, 1.6rem);
  letter-spacing: 0.05em;
  margin-bottom: -1.2em; }

.main-navigation__entry {
  display: flex;
  margin-right: 1.2em;
  margin-bottom: 1.2em; }
  .main-navigation__entry-link {
    display: flex;
    align-items: center;
    background-color: #2C2C2C;
    color: #FFF;
    padding: 0.25em;
    text-align: center;
    text-transform: uppercase; }
  .main-navigation__entry-link:hover {
    background-color: #349380; }
  .main-navigation__entry.is-active .main-navigation__entry-link {
    background: transparent;
    color: #FFF;
    outline: 1px solid currentColor; }
    .main-navigation__entry.is-active .main-navigation__entry-link:hover {
      color: #2C2C2C;
      outline: none; }

.main-navigation--inverted .main-navigation__entry-link {
  background-color: #FFF;
  color: #000; }
  .main-navigation--inverted .main-navigation__entry-link:hover {
    background-color: #349380;
    color: #FFF; }

@media (min-width: 42.5em) {
  .text-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .text-grid > * {
      width: calc(50% - 1em); }
    .text-grid-layout-50-50 {
      grid-template-columns: 1fr 1fr; } }

@supports (grid-gap: 2rem) {
  .text-grid {
    display: grid;
    grid-gap: 0 2em; }
    @media (min-width: 42.5em) {
      .text-grid {
        grid-gap: 1em 2em; }
        .text-grid > * {
          width: auto;
          margin: initial; }
        .text-grid-layout-50-50 {
          grid-template-columns: 1fr 1fr; }
        .text-grid-layout-75-25 {
          grid-template-columns: 3fr 1fr; }
        .text-grid-layout-25-75 {
          grid-template-columns: 1fr 3fr; }
        .text-grid-layout-66-33 {
          grid-template-columns: 2fr 1fr; }
        .text-grid-layout-33-66 {
          grid-template-columns: 1fr 2fr; }
        .text-grid-layout-33-33-33 {
          grid-template-columns: 1fr 1fr 1fr; }
        .text-grid-layout-50-25-25 {
          grid-template-columns: 2fr 1fr 1fr; }
        .text-grid-layout-25-50-25 {
          grid-template-columns: 1fr 2fr 1fr; }
        .text-grid-layout-25-25-50 {
          grid-template-columns: 1fr 1fr 2fr; }
        .text-grid-layout-25-25-25-25 {
          grid-template-columns: 1fr 1fr 1fr 1fr; } } }

.text-grid .rich-text ul, .text-grid .rich-text ol {
  margin-left: 0;
  padding-left: 2ch; }

.text-image-teaser {
  background-color: #2C2C2C; }

.text-image-teaser__content {
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  padding: 0 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem; }
  .text-image-teaser__content:after {
    content: " ";
    display: block;
    clear: both; }
  .text-image-teaser__content * {
    box-sizing: border-box; }

.text-image-teaser__heading {
  font-weight: bold; }
  .text-image-teaser__heading.text-image-teaser__heading {
    margin-top: 0;
    margin-bottom: 1em;
    color: #FFF; }

.text-image-teaser__text {
  color: #FFF; }
  .text-image-teaser__text a:not(.button) {
    color: #FFF; }

.text-image-teaser__image {
  position: relative; }
  .text-image-teaser__image img {
    width: 100%; }

.text-image-teaser__link {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: 200px; }
  .text-image-teaser__link .button.next.default {
    padding-right: 2rem;
    background-color: #349380; }
    .text-image-teaser__link .button.next.default:after {
      display: none; }
  .text-image-teaser__link a {
    color: #FFF; }

.text-image-teaser__image {
  margin-top: 2rem; }

@media (min-width: 50em) {
  .text-image-teaser__text {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .text-image-teaser__image {
    width: 40.67797%;
    float: right;
    margin-right: 0;
    margin-top: 0; } }

.text-video {
  background-color: #323232;
  color: #FFF; }
  .text-video__content {
    max-width: 96rem;
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
    padding: 0 2rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 2rem; }
    .text-video__content:after {
      content: " ";
      display: block;
      clear: both; }
    .text-video__content * {
      box-sizing: border-box; }
  .text-video__text {
    color: inherit;
    font-size: 4rem;
    line-height: 1.2; }

.text-video.-text-video .text-video__text {
  margin-bottom: 0.5em;
  margin-top: 0.5em; }

.text-video.-video-text .text-video__content {
  flex-direction: column-reverse; }

.text-video.-video-text .text-video__text {
  margin-bottom: 0.5em;
  margin-top: 0.5em; }

@media (min-width: 50em) {
  .text-video__content {
    flex-direction: row; }
  .text-video__text {
    display: flex;
    align-items: center;
    margin-bottom: 0; }
  .text-video.-text-video .text-video__text {
    width: 49.15254%;
    margin-right: 10.16949%; }
  .text-video.-text-video .text-video__video {
    width: 40.67797%; }
  .text-video.-video-text .text-video__content {
    flex-direction: row-reverse; }
  .text-video.-video-text .text-video__video {
    width: 49.15254%;
    margin-right: 10.16949%; }
  .text-video.-video-text .text-video__text {
    width: 40.67797%; } }

.interview-teaser__heading[class] {
  font-size: 2.4rem;
  margin-bottom: 3rem; }

@media (min-width: 40em) {
  .interview-teaser__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "interview-teaser-image interview-teaser-image" "interview-interviewee1 interview-interviewee2";
    grid-gap: 3rem 5.5rem; } }

.interview-teaser__figure-wrapper {
  grid-area: interview-teaser-image; }

.interview-teaser__interviewee-1 {
  grid-area: interview-interviewee1; }

.interview-teaser__interviewee-2 {
  grid-area: interview-interviewee2; }

.interview-teaser__figure {
  margin: 0;
  margin-bottom: 3rem; }
  @media (min-width: 40em) {
    .interview-teaser__figure {
      display: flex;
      flex-shrink: 1;
      flex-grow: 0; } }
  .interview-teaser__figure img {
    width: 100%; }

.interview-teaser__image-caption {
  font-weight: bold;
  color: inherit;
  margin: 0;
  line-height: 1.33; }
  @media (min-width: 40em) {
    .interview-teaser__image-caption {
      flex-shrink: 0;
      width: 30ch;
      margin-left: 2rem; } }

.interview-teaser__interviewee .interviewee {
  display: inline-block;
  text-transform: uppercase;
  background-color: #349380;
  color: #FFF;
  font-size: 0.8em;
  padding: 0.1em 0.5em;
  font-weight: bold;
  line-height: 1.2;
  vertical-align: middle; }

.tag_cta {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #349380;
  color: #FFF;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
  padding-left: 20px; }
  .tag_cta:hover, .tag_cta:focus, .tag_cta:active {
    text-decoration: none; }
  .tag_cta:before, .tag_cta:after {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .tag_cta:hover, .tag_cta:focus, .tag_cta:active {
    background-color: #349380; }
    .tag_cta:hover:before, .tag_cta:hover:after, .tag_cta:focus:before, .tag_cta:focus:after, .tag_cta:active:before, .tag_cta:active:after {
      transform: translate(5px); }

.tag_heading {
  margin-left: 0.3em; }
  .tag_heading:not(.no-break) {
    max-width: 30ch; }
  .tag_heading__text {
    display: inline;
    padding: 0.625rem 0 0.375rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: -0.025em;
    color: #FFF;
    background-color: #349380;
    box-shadow: 1rem 0 0 #349380, -1rem 0 0 #349380;
    box-decoration-break: clone; }

.tag_icon-link a:not(.button):hover, .tag_icon-link a:not(.button):active, .tag_icon-link a:not(.button):focus {
  text-decoration: none; }
  .tag_icon-link a:not(.button):hover .icon-container__icon, .tag_icon-link a:not(.button):active .icon-container__icon, .tag_icon-link a:not(.button):focus .icon-container__icon {
    color: #349380;
    border-color: #349380;
    transform: translate(5px, 0); }
  .tag_icon-link a:not(.button):hover .icon-container__label, .tag_icon-link a:not(.button):active .icon-container__label, .tag_icon-link a:not(.button):focus .icon-container__label {
    color: #349380; }

.tag_icon-link .icon-container__icon {
  color: #349380;
  height: 4rem;
  width: 4rem;
  padding: 0.5em;
  border: 1px solid #349380;
  border-radius: 50%;
  transition: transform 0.25s cubic-bezier(0.23, 1, 0.32, 1); }

.tag_icon-link .icon-container.has-text .icon-container__label {
  color: #1D1D1B;
  text-transform: uppercase;
  margin-left: 1em; }

.tag_icon-link.-align-icon-left {
  text-align: left; }

.tag_icon-link.-align-icon-right {
  text-align: right; }

.tag_icon-link.-align-icon-center {
  text-align: center; }

.tag_image {
  margin: 0; }

.tag_intro {
  font-weight: bold;
  margin-bottom: 3em; }

.tag_quote--big {
  color: #349380; }

.module {
  --spacing-before: var(--module-spacing-default);
  --spacing-after: var(--module-spacing-default); }
  .module.-spacing-after-neg-sm {
    padding-bottom: calc(7rem + var(--module-spacing-sm));
    margin-bottom: var(--spacing-after-neg-sm); }
  .module.-spacing-after-neg-md {
    padding-bottom: calc(7rem + var(--module-spacing-md));
    margin-bottom: var(--spacing-after-neg-md); }
  .module.-spacing-after-neg-lg {
    padding-bottom: calc(7rem + var(--module-spacing-lg));
    margin-bottom: var(--spacing-after-neg-lg); }
  .module.-spacing-before-default {
    margin-top: var(--spacing-before-default); }
  .module.-spacing-before-none {
    margin-top: var(--spacing-before-none); }
  .module.-spacing-before-sm {
    margin-top: var(--spacing-before-sm); }
  .module.-spacing-before-md {
    margin-top: var(--spacing-before-md); }
  .module.-spacing-before-lg {
    margin-top: var(--spacing-before-lg); }
  .module.-spacing-after-default {
    margin-bottom: var(--spacing-after-default); }
  .module.-spacing-after-none {
    margin-bottom: var(--spacing-after-none); }
  .module.-spacing-after-sm {
    margin-bottom: var(--spacing-after-sm); }
  .module.-spacing-after-md {
    margin-bottom: var(--spacing-after-md); }
  .module.-spacing-after-lg {
    margin-bottom: var(--spacing-after-lg); }
  .module__content .module {
    padding: 0; }

.module_inline-page {
  margin: 0;
  padding: 0;
  max-width: none; }

.module_text-grid:first-child .rich-text h1 {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 2.1rem;
  font-weight: bold;
  color: inherit;
  text-transform: uppercase; }

.module_text-image-teaser {
  box-sizing: border-box;
  padding: 0;
  max-width: none;
  background-color: #323232; }

.module_text-video {
  box-sizing: border-box;
  padding: 0;
  max-width: none;
  background-color: #323232; }

.site-footer {
  background-color: #323232;
  border-top: 2px solid #FFF; }

.site-footer__logo .logo__link {
  width: 13rem; }

.site-footer__content {
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  padding: 0 2rem;
  padding: 2rem; }
  .site-footer__content:after {
    content: " ";
    display: block;
    clear: both; }
  .site-footer__content * {
    box-sizing: border-box; }

.site-footer .compact-navigation-bar__navigation {
  display: none; }

.site-footer .compact-navigation-bar__logo {
  margin-top: 0; }

.site-header {
  background-color: #FFF;
  padding-top: 2rem;
  border-bottom: 2px solid #000; }
  @media (min-width: 35em) {
    .site-header {
      padding-bottom: 3rem; } }

.site-header__content {
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  padding: 0 2rem; }
  .site-header__content:after {
    content: " ";
    display: block;
    clear: both; }
  .site-header__content * {
    box-sizing: border-box; }

.site-header--inverted {
  background-color: #323232;
  color: #FFF;
  border-bottom: none; }

.site-header .logo-vaillant,
.site-header .logo-push-digital {
  margin: auto 0; }

.site-header .logo-vaillant .logo__link {
  width: 13rem; }

@media (min-width: 35em) {
  .site-header .logo-vaillant {
    margin: 0 0 4rem; } }

.site-header .logo-push-digital {
  justify-content: flex-start;
  fill: #FFF; }

.site-header .logo-push-digital {
  height: 30px; }
  @media (min-width: 35em) {
    .site-header .logo-push-digital {
      height: 60px;
      margin-bottom: 1.7em; } }

.site-header .hero-headline {
  margin-bottom: 0.5em; }

.site-header .logo-vaillant {
  grid-area: header-logo-vaillant; }

.site-header .logo-campaign {
  grid-area: header-logo-campaign;
  align-self: end;
  justify-self: end; }

.site-header .logo-push-digital,
.site-header .hero-headline {
  grid-area: header-headline; }

.site-header .main-navigation {
  grid-area: header-nav; }

.site-header__content {
  display: grid;
  grid-template-areas: "header-headline header-logo-vaillant" "header-nav header-nav";
  grid-template-columns: 1fr auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 2rem; }
  .site-header__content .logo-campaign {
    display: none; }
  @media (min-width: 35em) {
    .site-header__content {
      grid-template-areas: "header-logo-vaillant header-logo-vaillant" "header-headline header-logo-campaign" "header-nav header-nav"; }
      .site-header__content .logo-vaillant {
        grid-area: header-logo-campaign;
        margin-bottom: auto; }
      .site-header__content .logo-campaign {
        display: block; } }

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #323232; }

.sticky-header__content {
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  padding: 0 2rem;
  padding: 2rem; }
  .sticky-header__content:after {
    content: " ";
    display: block;
    clear: both; }
  .sticky-header__content * {
    box-sizing: border-box; }

.sticky-header {
  transform: translateY(-100%);
  transition: transform 0.25s cubic-bezier(0.23, 1, 0.32, 1); }
  .sticky-header.is-visible {
    transform: translateY(0); }

:root {
  --module-spacing-default: 10rem;
  --module-spacing-sm: calc(var(--module-spacing-default) / 2);
  --module-spacing-md: var(--module-spacing-default);
  --module-spacing-lg: calc(var(--module-spacing-default) * 1.5);
  --module-spacing-neg-sm: calc(-1 * var(--module-spacing-sm));
  --module-spacing-neg-md: calc(-1 * var(--module-spacing-md));
  --module-spacing-neg-lg: calc(-1 * var(--module-spacing-lg));
  --spacing-before-default: var(--module-spacing-default);
  --spacing-after-default: var(--module-spacing-default);
  --spacing-before-none: 0;
  --spacing-after-none: 0;
  --spacing-before-sm: var(--module-spacing-sm);
  --spacing-after-sm: var(--module-spacing-sm);
  --spacing-before-md: var(--module-spacing-md);
  --spacing-after-md: var(--module-spacing-md);
  --spacing-before-lg: var(--module-spacing-lg);
  --spacing-after-lg: var(--module-spacing-lg);
  --spacing-before-neg-sm: var(--module-spacing-neg-sm);
  --spacing-after-neg-sm: var(--module-spacing-neg-sm);
  --spacing-before-neg-md: var(--module-spacing-neg-md);
  --spacing-after-neg-md: var(--module-spacing-neg-md);
  --spacing-before-neg-lg: var(--module-spacing-neg-lg);
  --spacing-after-neg-lg: var(--module-spacing-neg-lg); }

.content {
  margin-top: 3rem; }

.logo {
  display: flex;
  justify-content: flex-end;
  user-select: none; }
  .logo__link {
    display: inline-block; }
  .logo__image {
    width: 100%;
    height: 100%; }

.article__anchor-navigation {
  position: fixed;
  width: 24rem;
  top: 15rem; }

.article__hero-heading[class] {
  position: relative;
  font-size: inherit; }
  @media (min-width: 0.0625em) and (max-width: 39.9375em) {
    .article__hero-heading[class] {
      padding: 0.6em;
      color: #FFF;
      font-size: 2.3rem;
      line-height: 1.3em; } }
  @media (min-width: 40em) {
    .article__hero-heading[class] {
      display: inline;
      padding: 0.625rem 0 0.375rem;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.2;
      letter-spacing: -0.025em;
      color: #FFF;
      background-color: #349380;
      box-shadow: 1rem 0 0 #349380, -1rem 0 0 #349380;
      box-decoration-break: clone;
      bottom: auto;
      left: auto; } }

.article__hero .content-wrapper {
  background-color: #349380; }

.article__hero-heading-wrapper {
  font-size: 4rem; }
  @media (min-width: 40em) {
    .article__hero-heading-wrapper {
      margin-left: 1rem; } }

.category__content .module__content {
  padding: 0; }
  .category__content .module__content .content-wrapper {
    padding: 0; }

.category__content .category__article-heading {
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  padding: 0 2rem;
  color: #abc32b; }
  .category__content .category__article-heading:after {
    content: " ";
    display: block;
    clear: both; }
  .category__content .category__article-heading * {
    box-sizing: border-box; }

.page-strategy .module_text-grid:nth-child(2) .rich-text h2 {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 2.1rem;
  font-weight: bold;
  color: inherit;
  text-transform: uppercase; }

.page-strategy .rich-text h2 {
  text-transform: uppercase; }

.page-strategy .rich-text h3 {
  text-transform: uppercase; }

.page-strategy .tag_cta {
  background: transparent;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 52px;
  background-color: #349380;
  color: #FFF;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px; }
  .page-strategy .tag_cta:hover, .page-strategy .tag_cta:focus, .page-strategy .tag_cta:active {
    text-decoration: none; }
  .page-strategy .tag_cta:before, .page-strategy .tag_cta:after {
    background: url("../images/sprites/icons-small/arrow-light-right-white.png") no-repeat center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -13px; }
  .page-strategy .tag_cta:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 13px; }
  .page-strategy .tag_cta:before, .page-strategy .tag_cta:after {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .page-strategy .tag_cta:hover, .page-strategy .tag_cta:focus, .page-strategy .tag_cta:active {
    background-color: #349380; }
    .page-strategy .tag_cta:hover:before, .page-strategy .tag_cta:hover:after, .page-strategy .tag_cta:focus:before, .page-strategy .tag_cta:focus:after, .page-strategy .tag_cta:active:before, .page-strategy .tag_cta:active:after {
      transform: translate(-5px); }

html {
  scroll-behavior: smooth; }
